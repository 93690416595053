<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>
    <div class="container">
        <div class="card text-center m-3">
            <h3 class="card-header">Vue.js Pagination Tutorial & Example</h3>
            <div class="card-body">
                <ul>
                    <li v-for="item in getItems">{{item}}</li>
                </ul>
            </div>

            <paginate :page-count="getPageCount" :page-range="3" :margin-pages="2" :click-handler="clickCallback" :prev-text="'＜'" :next-text="'＞'" :container-class="'pagination'" :page-class="'page-item'"></paginate>
        </div>
    </div>
</template>

<script>
    import Paginate from 'vuejs-paginate-next';

    var items = [];

    for(var i=1; i<=100; i++){
        items.push('item-'+i);
    }


    export default {
        name: "testPagination",
        components: {
            paginate: Paginate
        },
        data() {
            return {
                items: items,
                perPage: 10,
                currentPage: 1
            };
        },
        computed: {
            getItems: function() {
                let current = this.currentPage * this.perPage;
                let start = current - this.perPage;

                console.log(items);

                return this.items.slice(start, current);
            },
            getPageCount: function() {
                return Math.ceil(this.items.length / this.perPage);
            }
        },
        watch: {
            perPage: function (oldArg, newArg) {
                this.pageChanged(this.currentPage)
            }
        },
        methods: {
            clickCallback: function (pageNum) {
                this.currentPage = Number(pageNum);
            }
        }
    }
</script>

<style scoped>
    body {
        background: #20262E;
        padding: 20px;
    }

    li {
        margin: 8px 0;
    }

    h2 {
        font-weight: bold;
        margin-bottom: 15px;
    }

    del {
        color: rgba(0, 0, 0, 0.3);
    }
</style>