<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="mentouri" > شخصيات > بلقاسم حبة</div>
        </div>
    </div>

    <div class="container mt-5 mb-5 belkacem" dir="rtl">
        <h3><span>|</span>بلقاسم حبة</h3>

        <div class="row mt-5 mb-5">
            <div class="col-md-6 mb-2">
                <a target="_blank" href="/images/independence.jpg">
                    <img src="/images/independence.jpg" width="300" class="img-thumbnail image float-end float-md-start justify-content-center" />
                </a>
            </div>
            <div class="col-md-6">
                <a target="_blank" href="/images/habba.png">
                    <img src="/images/habba.png" width="300" class="img-thumbnail image" />
                </a>
            </div>
        </div>

        <div class="description_habba">
            <p style="background-color: #E7E9EB!important;" class="p-2">
                هو العربي الأكثر إختراعا ب1500 براءة إختراع ساهمت بشكل كبير في تطوير مجال التكنولوجيا إلى ماهو عليه اليوم، هو العالم الجزائري بلقاسم حبة  ابن مدينة المغير، من مواليد 1957. تخرج من جامعة هواري بومدين   للعلوم والتكنولوجيا سنة 1980 حاملا لشهادة جامعية في الدراسات الفيزيائية المعمقة، واصل دراسته بعدها في جامعة ستانفورد بالولايات المتحدة الأمريكية، حيث تحصل على شهادتي ماستر، واحدة في الفيزياء التطبيقية والأخرى في مجال علوم المادة و كذالك شهادة  دكتوراه في مجال الطاقة الشمسية.
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                باشر بعدها عمله في مجال الأبحاث مع العملاق في الإعلام الآلي IBM أين بدأ فيها رحلة إختراعاته. وكانت أول براءة إختراع له سنة 1990 حيث أبتكرطريقة لنحت الرقاقة الإكترونية التي تقرأ المعلومات من على القرص الصلب باستخدام أشعة الليزر، وفي نفس المجال كان له الفضل في ابتكار تكنولوجيا سمحت بتقليص حجم الهواتف والحواسيب، المستخدمة في أغلب الهواتف حاليا، كما ساهم أيضا مع شركة Rumbus memory  في تطوير تكنولوجيا الذاكرة التي إستخدمتها شركة Sony في أجهزة Playstation  2 و 3.
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                بدأ الأستاذ بلقاسم حبة مسيرته المهنية مع شركة IBM  وبعدها مع NEC إلّا أنه غيّر وجهته فيما بعد نحو المؤسسات الناشئة فانضم إلى Xperi Holding Corp التي ساهم معها في تطوير تكنولوجيا الكاميرات المستخدمة في الهواتف خاصة، والتي يشغل  فيها حاليا منصب نائب رئيس، كما أسس شركة SiliconPipe  التي اشتغلت على تكنولوجيا تساعد في تطويرحركة الإلكترونات داخل المخطط الكهربائي، التي تم شراؤها فيما بعد من طرف شركة Samsung.
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                مساهمة الدكتور بلقاسم حبة لاتنتهي عند هذا الحد، حيث اشتغل أيضا على زيادة قوة الهواتف أكثر وهذا بالإنتقال من الإلكترونيك ثنائي الأبعاد إلى ثلاثي الأبعاد، الشيء الذي قد يغير من مجرى التكنولوجيا الحديثة، بالإضافة إلى كل هذا يساهم أيضا في تطوير تكنولوجيا التعلم الآلي والواقع الإفتراضي.
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                زيادة عن إهتمامه بمجال التكنولوجيا ساهم بلقاسم حبة في خدمة مجتمع العلم والتكنولوجيا في الجزائر خاصة والعالم العربي بصفة عامة  حيث شارك في  تأسيس المؤسسة الأمريكية الجزائرية للثقافة، التعليم، العلوم والتكنولوجيا، و منظمة Algerian Start Up initiative، كما أسس أيضا موقع Algerian Inventors الذي جمع أعضاؤه مايقارب 6000 براءة إختراع.
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                كلْلت هذه المسيرة الحافلة بالإنجازات و الإبتكارات بعدد كبير من الجوائز والتكريمات أبرزها تصنيفه ضمن 100 عالم الأكثر إنتاجية في العالم لأكثر من مرة، وسام الإستحقاق الوطني(رتبة عشير) سنة 2021 التي سلمت له من طرف رئيس الجمهورية ،وقبلها جائزة وسام العالم الجزائري سنة 2015 .
            </p>
            <p style="background-color: #E7E9EB!important;" class="p-2">
                عُيّن الدكتور بلقاسم حبة من طرف رئيس الجمهورية عبد المجيد تبون عضوا في المجلس الوطني للبحث العلمي والتكنولوجيا.
            </p>
            <p>

            </p>
            <h6><span>في الرابط التالي يمكنكم الإطلاع على أغلب إختراعاته:</span></h6>
            <p>
                <a style="text-decoration: none; color: #22845B" target="_blank" href="https://worldwide.espacenet.com/patent/search/family/040252396/publication/US2009014861A1?q=in%20%3D%20'belgacem'%20AND%20ia%20%3D%20'haba'">
                    https://worldwide.espacenet.com/patent/search/family/040252396/publication/US2009014861A1?q=in%20%3D%20"belgacem"%20AND%20ia%20%3D%20"haba"
                </a>
            </p>
        </div>

    </div>

</template>

<script>
    export default {
        name: "Belkacem"
    }
</script>

<style scoped>
    .belkacem h4 span{
        font-size: 35px;
        margin-left: 10px
    }
    .belkacem{
        font-family: DroidArabic!important;
    }
    .belkacem p{
        font-size: var(--s-font-size);
    }
    .belkacem h6{
        font-size: var(--s-font-size);
    }
    .belkacem h3{
        margin-bottom: 25px;
        font-size: var(--l-font-size);

    }
    .belkacem h4 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
        font-size: var(--ml-font-size);

    }
    .belkacem h3 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .belkacem h4{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .description_habba h6 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
</style>