import { createRouter, createWebHistory } from "vue-router"

import Carousel       from '../components/Carousel'
import LatestNews     from '../components/LatestNews'
import Studies        from '../components/Studies'
import Corona         from '../components/Corona'
import Monkey         from '../components/Monkey'
import ActOne         from '../components/details/Act-01'
import ActTwo         from '../components/details/Act-02'
import ActThree       from '../components/details/Act-03'
import ActFour        from '../components/details/Act-04'
import HeatWave       from '../components/HeatWave'
import Zerhouni       from '../components/personalities/Zerhouni'
import Mentouri       from '../components/personalities/Mentouri'
import Belkacem       from '../components/personalities/Belkacem'
import Detail_article from '../components/details/Detail_article';
import Articles       from '../components/Articles';
import PageNotFound   from '../components/PageNotFound';
import testPagination from '../components/testPagination';

// President agency
import Biography from '../components/Biography'
import Missions from '../components/Missions'
import OrganizationalStructure from '../components/OrganizationalStructure'
import ScientificBoard from '../components/ScientificBoard'
import WordPresident from '../components/WordPresident'
import Legislation from '../components/Legislation'
import Statement from '../components/Statement'

const routes = [
    {
        name: 'Studies',
        path: '/aid-adha',
        components: {Studies}
    },
    {
        name: 'Corona',
        path: '/corona',
        components: {Corona}
    },
    {
        name: 'Monkey',
        path: '/monkey',
        components: {Monkey}
    },
    {
        name: 'ActOne',
        path: '/actone',
        components: {ActOne}
    },
    {
        name: 'ActThree',
        path: '/actthree',
        components: {ActThree}
    },
    {
        name: 'ActTwo',
        path: '/acttwo',
        components: {ActTwo}
    },
    {
        name: 'ActFour',
        path: '/Actfour',
        components: {ActFour}
    },
    {
        name: 'Biography',
        path: '/biography',
        components: {Biography}
    },
    {
        name: 'Missions',
        path: '/missions',
        components: {Missions}
    },
    {
        name: 'OrganizationalStructure',
        path: '/OrganizationalStructure',
        components: {OrganizationalStructure}
    },
    {
        name: 'ScientificBoard',
        path: '/ScientificBoard',
        components: {ScientificBoard}
    },
    {
        name: 'WordPresident',
        path: '/WordPresident',
        components: {WordPresident}
    },
    {
        name: 'Legislation',
        path: '/legislation',
        components: {Legislation}
    },
    {
        name: 'Statement',
        path: '/statement',
        components: {Statement}
    },
    {
        name: 'HeatWave',
        path: '/heatwave',
        components: {HeatWave}
    },
    {
        name: 'Zerhouni',
        path: '/zerhouni',
        components: {Zerhouni}
    },
    {
        name: 'Mentouri',
        path: '/mentouri',
        components: {Mentouri}
    },
    {
        name: 'Belkacem',
        path: '/belkacem',
        components: {Belkacem}
    },
    {
        name: 'Articles',
        path: '/all-articles',
        components: {Articles}
    },
    {
        name: 'Detail_article',
        path: '/detail_article/:id?',
        components: {Detail_article}
    },
    {
        name: 'testPagination',
        path: '/test-pagination',
        components: {testPagination}
    },
    {
        name: 'PageNotFound',
        path: '/:pathMatch(.*)*',
        components: {PageNotFound}
    },
    {
        path: '/',
        components: {
            Carousel,LatestNews
        },

    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
