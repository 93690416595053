<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{ $t('agency.biography.title') }}</div>
        </div>
    </div>

    <div class="container " dir="rtl">
        <div>
            <div class="title" style="font-family: DroidArabic!important;" >السيرة الذاتية</div>
        </div>
        <div class="row biography_president">
            <div class="col-sm-12 col-md-7 mt-4" >
                <ul >
                    <li>كمال صنهاجي حاصل على دكتوراه في الصيدلة من جامعة ليون 1 عام 1983 ودكتوراه دولة في علوم المناعة، ثم أهلية للإشراف على البحوث من نفس الجامعة عام 1987.</li>
                    <li>ألف البروفسيور العديد من الكتب و المنشورات العلمية في مختلف المجلات العلمية العالمية، ومن ابرز مؤالفاته كتاب “السيدا: أسلحة وعتاد” نشره ديوان المطبوعات الجامعية في عام 1996.</li>
                    <li>حصل على وسام الاستحقاق من وزارة الصحة والسكان بالجزائر عام 1997.</li>
                    <li>
                        كان أستاذًا جامعيًا وممارسًا إستشفائيا لمستشفى إدوار هيريو في ليون بفرنسا، حيث ترأس مختبر نقص المناعة وعلم مناعة زرع الأعضاء.  أدى بحثه التجريبي من ناحية، إلى تطوير العلاج الجيني ضد فيروس الإيدز، ومن ناحية أخرى، إلى تحريض التحمل في زراعة الأعضاء والخلايا الجذعية على الرغم من عدم توافق الأنسجة بين المتبرع والمتلقي. عمله البحثي هو نتيجة تعاون مع البروفيسور جان لوي تورين، المتخصص العالمي في زراعة الخلايا الجذعية للأطفال الذين يعانون من نقص المناعة الشديد (أطفال الفقاعات) ومع البروفيسور لوك مونتانييه، الحائز على جائزة نوبل في الطب عام 2008 لاكتشاف فيروس الإيدز.
                    </li>
                    <li>
                        شغل منصب مدير وحدة علم المناعة وعلم الأمراض المناعية في مركز البحوث في البيوتكنولوجيا (CRBt) في قسنطينة من 2015 إلى 2019،  ثم مديرا لمركز البحث في العلوم الصيدلانية (CRSP) في قسنطينة من 2019 إلى 2020.
                    </li>
                    <li>
                        في 13 جوان 2020 عين رئيساً للوكالة الوطنية للأمن الصحي (الجزائر) ومستشاراً لرئيس الجمهورية.
                    </li>
                </ul>
            </div>
            <div class="col-sm-12 col-md-5 mt-4">
                <div class="text-center">
                    <a target="_blank" href="/images/biography.jpg">
                        <img width="80%" src="../assets/biography.jpg"  class="img-thumbnail" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "biography"
    }
</script>

<style scoped>
    .biography_president ul li{
        font-family: DroidArabic!important;
        line-height: 1.7;
        padding-bottom: calc(20/16 * var(--default-size-unit));
    }



</style>