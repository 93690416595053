<template>

    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('studies.title') }} > {{ $t('studies.heatWave.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <div>
            <div class="title" style="font-family: DroidArabic!important;" >موجة الحر</div>
        </div>

        <div class="row heatWave">
            <div class="col-sm-12 col-md-12 col-lg-9 mt-4">
                <div class="p-2 mb-3" style="background-color: #E7E9EB!important;">
                    <p>
                        تُذكِّر الوكالة الوطنية للأمن الصحي بالنصائح الأساسية التي يجب اعتمادها في موجة الحر، والتي تعني الجميع، بما في ذلك الأشخاص الذين يتمتعون بصحة جيدة. من الضروري تكييف سلوكياتنا لحماية أنفسنا والحفاظ على قدرتنا على رعاية الأشخاص الأكثر ضعفًا من حولنا، خاصة في الأيام الحارة.
                    </p>
                </div>

                <h6><span>ماهي موجة الحر؟</span></h6>
                <div class="p-3 mb-3"  style="background-color: #E7E9EB!important;">
                    <p>
                        موجة الحر هي موجة تتميز بارتفاع غير طبيعي لدرجات الحرارة أثناء النهار والليل، وتستمر لعدة أيام أو أسابيع. اذ يعتمد تعريف الموجة الحرارية على عاملين: الحرارة والمدة.
                        في الجزائر، تصدر مصلحة التنبؤ بالمكتب الوطني للأرصاد الجوية نشرات جوية خاصة لموجات الحر عندما تصل
                        درجة الحرارة المتوقعة إلى 40 درجة مئوية خلال يومين متتاليين في المناطق الساحلية، و44 درجة مئوية في
                        المناطق الداخلية والهضاب العليا، و48 درجة مئوية خلال يوم واحد في المناطق الصحراوية.
                    </p>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 mt-4">
                <div class="imageflottante">
                    <div class="block-image">
                        <a target="_blank" href="/images/canicule.jpg">
                            <img src="/images/canicule.jpg"  class="img-thumbnail image" />
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <h6><span>إلى ماذا يؤدي التعرض الى درجات حرارة مرتفعة؟</span></h6>
                <div class="p-3 mb-3"  style="background-color: #E7E9EB!important;">
                    <p>
                        قد يترتب على ارتفاع درجات الحرارة بشكل كبير اصابات صحية متعددة، مثل:
                    </p>
                    <ul>
                        <li>ضربة الشمس: حيث يصاب الإنسان عادة بضربة الشمس عند تعرضه لدرجة حرارة مرتفعة ويرافقها نسبة رطوبة عالية لمدة زمنية مطولة نسبيا؛</li>
                        <li>فرط الحرارة: تظهر الإصابة بفرط الحرارة لدى الإنسان بعد قيامه بمجهود جسمي لفترة طويلة في طقس حار ودرجات حرارة مرتفعة إضافة إلى وجود رطوبة عالية، وتعرض الجسم إلى عدة مخاطر قد تصل في بعض الحالات إلى الوفاة؛</li>
                        <li>الجفاف: ينقسم حسب درجته إلى ثلاث فئات:</li>
                        <div class="drought">
                            <h6 class="mt-2"><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 5px;" />. الجفاف الخفيف: ومن أعراضه الشعور بالعطش وفقدان الشهية، بالإضافة الى جفاف الجلد وانخفاض القدرات الجسدية بحوالي الثلث.</h6>
                            <h6 class="mt-1"><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 5px;" />. الجفاف المتوسط: يمتاز بعدة أعراض كالشعور بالعطش، بالتعب وبالإرهاق، بالإضافة الى الشعور بالصداع والدوار وجفاف في الفم، وقد يؤدي أيضا الى انخفاض سريع في ضغط الدم عند التحول من وضعية الجلوس إلى الوقوف، والذي يؤدي بدوره إلى الإصابة بالدوار والإغماء.</h6>
                            <h6 class="mt-1"><font-awesome-icon icon="fa-solid fa-3" style = "margin-left: 5px;" />. الجفاف المرتفع: تعتبر مرحلة متقدمة من الإصابة بالجفاف حيث تحدث بسبب فقدان 5% أو أكثر من سوائل الجسم، وتتميز بعدة أعراض كخفقان سريع في القلب وزيادة في وتيرة التنفس، انخفاض في ضغط الدم، الشعوربالنعاس، الإغماء، تجعد الجلد والبشرة، بالإضافة الى تشنج العضلات، والاختلاج.</h6>

                        </div>
                    </ul>
                </div>
                <h6><span>من هم الأشخاص الأكثر تضررا من موجة الحر؟</span></h6>
                <div class="p-3 mb-3"  style="background-color: #E7E9EB!important;">
                    <p>يمكن أن تؤثر موجة الحر على أي شخص، ولكن الأشخاص الأكثر تضررا هم:</p>

                    <ul>
                        <li>كبار السن، وخاصة أولئك الذين تزيد أعمارهم عن 75 عامًا؛</li>
                        <li>الرضع والأطفال الصغار؛</li>
                        <li>الأشخاص الذين يعانون من الأمراض المزمنة، خاصةً مشاكل في القلب أو التنفس وكذا الأشخاص الذين يعانون من اضطرابات عقلية؛</li>
                        <li>الأشخاص الذين يتناولون أدوية معينة، بما في ذلك الأدوية التي تؤثر على التعرق والتحكم في درجة الحرارة؛</li>
                        <li>الأشخاص النشطون بدنيًا كالعمال والرياضين.</li>
                    </ul>
                </div>
                <h6><span>كيف يجب التعامل مع موجة الحر؟</span></h6>
                <div class="p-3 mb-3"  style="background-color: #E7E9EB!important;">
                    <ul class="mb-4">
                        <li>شرب الماء بانتظام دون انتظار الشعور بالعطش؛</li>
                        <li>تناول وجبات صحية خفيفة مع إكثار السوائل وتجنب المشروبات التي تحتوي على الكافيين أو السكر لأنها تؤدي إلى فقدان السوائل من الجسم؛</li>
                        <li>إمكانية اللجوء في بعض الحالات إلى إضافة كمية معتدلة من شراب سكري مركز (sirop)  في الماء من أجل تحفيز كبار السن على الشرب؛</li>
                        <li>تبريد وتبليل الجسم (على الأقل الوجه والساعدين) عدة مرات في اليوم؛</li>
                        <li>إرتداء ملابس خفيفة وذات ألوان فاتحة؛</li>
                        <li>تجنب الخروج خلال الساعات الحارة (من الساعة الحادية عشر صباحا الى الساعة الثالثة بعد الزوال)؛</li>
                        <li>عند ضرورة الخروج في الحر، يستحسن المشي في الظل، واستخدام واقيًا من الشمس وارتداء قبعة ونظارات شمسية؛</li>
                        <li>تجنب المجهود البدني الشديد؛</li>
                        <li>الحافظ على برودة الغرف بغلق النوافذ أثناء النهار، وفتحها في المساء مع استخدام الستائر ذات الألوان الفاتحة أو المواد العاكسة خارج النوافذ؛</li>
                        <li>عدم التردد في استشارة الطبيب أو الصيدلي بالنسبة لذوي الأمراض المزمنة أو العلاج الدوائي المنتظم؛</li>
                        <li>للحصول على معلومات في الوقت الفعلي، يمكن الاطلاع على النشرات الجوية الخاصة لمصلحة التنبؤ بالمكتب الوطني للأرصاد الجوية، والتي يتم تحديثها يوميا.</li>
                    </ul>
                </div>

            </div>


        </div>

    </div>
</template>

<script>
    export default {
        name: "HeatWave"
    }
</script>

<style scoped>
    .heatWave h6 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .heatWave p{
        font-size: var(--s-font-size)!important;
    }
    .heatWave{
        font-family: DroidArabic!important;
    }
</style>