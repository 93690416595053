<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" > {{  $t('agency.title') }} > البيانات الصحفية </div>
        </div>
    </div>

    <div class="container" dir="rtl" >
        <div class="title " >البيانات الصحفية</div>

        <div class="row mb-5">
            <div class="px-lg-5 ressource mt-5 mb-5 " >
                <div class="container d-flex pb-5 card-bloc" style="" >

                    <div class="card-group row mt-5">
                        <div v-for="item in broadcasts" class="cadre col-md-12 col-lg-6 px-xl-5">
                            <div class="cadre__inner" >
                                <a :href="url_back+item.url_file" target="_blank" >{{ item.title }}</a>
                                <a :href="url_back+item.url_file" download target="_blank" > <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp; </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Statement",
        data(){
            return {
                broadcasts:this.broadcasts,
                url_back:"https://backend-nssa.el-mouradia.dz/documents/"
            }
        },
        created(){
            this.getBroadcasts();
        },
        methods:{
            async getBroadcasts(){
                let url = 'https://backend-nssa.el-mouradia.dz/api/broadcasts';
                await axios.get(url).then(response =>{
                    this.broadcasts = response.data.broadcasts;
                    // console.log(this.broadcasts);
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped>

    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;

    }
    .card-bloc{
        box-shadow: 5px 5px 30px #5a6268;
        min-height: 400px;
        justify-content: center;
        flex-direction: column-reverse
    }

    .card-group .cadre .cadre__inner{
        background-color: rgba(255,204,0, 0.7);
        margin: 10px 20px;
        box-shadow: 5px 5px 20px #051a27;
        height: 100px;
        padding-top: 20px;
        border-radius: 4px;
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }
    .card-group .cadre .cadre__inner a{
        padding:7px 15px;
        color: #282a2e !important;
        font-size:var(--m-font-size);
        text-decoration: none;
        text-shadow: 0 0 5px rgb(250, 250, 250);
    }
    .card-group .cadre .cadre__inner a:hover,.card-group .cadre .cadre__inner i:hover{
        color: black !important;
        font-weight: bold;
        text-shadow: 0 2px 2px rgb(250, 250, 250);


    }
    .card-group .cadre .cadre__inner i{
        color:gray;
        vertical-align: middle;
        box-shadow: 5px 5px 10px #000000;

    }
    .cadre__inner{
        direction: ltr;
    }
    .card-group{
        padding-top: auto;
        padding-bottom: auto;
    }

    @media(max-width: 415px){
        .card-bloc{
            box-shadow: 5px 5px 30px #5a6268;
            /*height: 500px;*/
            justify-content: center;
            flex-direction: column-reverse
        }

        .card-group .cadre .cadre__inner{
            padding-top: 7px!important;
            height: 100px !important;
            padding-left: 5px;
            padding-right: 5px;
        }
        .cadre__inner a{
            padding-left: 5px;
            padding-right: 5px;
            line-height: 1.3;
            font-size: var(--s-font-size);


        }
    }

</style>