<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="mentouri" > شخصيات > بشير منتوري</div>
        </div>
    </div>

    <div class="container mt-5 mb-5 mentouri" dir="rtl">
        <h3><span>|</span> بشير منتوري</h3>

        <div class="row">
            <div class="centre-title" ><font-awesome-icon class="quote" icon="fa fa-quote-right" />&nbsp; الابتكار في جراحة الكيس المائي للكبد</div>
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-md-6 mb-2">
                <a target="_blank" href="/images/independence.jpg">
                    <img src="/images/independence.jpg" width="300" class="img-thumbnail image float-end float-md-start justify-content-center" />
                </a>
            </div>
            <div class="col-md-6">
                <a target="_blank" href="/images/mentouri.jpg">
                    <img src="/images/mentouri.jpg" width="300" class="img-thumbnail image" />
                </a>
            </div>
        </div>

        <div class="row ">
            <div class="col-12">
                <div style="background-color: #E7E9EB!important;" class="mb-4 p-2">
                    <p class="mt-2">
                        مع اقتراب عيد الأضحى، ككل عام، العديد من التساؤلات ذات طابع صحي يعاد طرحها وغالبًا ما تكون الأكياس المائية في صلب الإشكالية، وهذا ما أدى إلى دراسات عديدة تتعلق باستخراج هذه الأكياس من الأعضاء المتضررة وإبعادها عن الحيوانات، بهدف تجنب تلوث هذه الأخيرة، ولا سيما إصابة الإنسان وأمنه الصحي.
                    </p>
                </div>
            </div>
        </div>

        <h4><span>غير أن في حالة إصابة الإنسان، ماذا يطرأ؟ وكيف يجب الإستجابة لذلك؟ </span></h4>

        <div style="background-color: #E7E9EB!important;" class="mb-4 p-3">
            <p class="mt-2">
                إهتماما بهذه الإشكالية الصحية يؤيد البروفيسور بشير منتوري، سليل مدرسة الجزائر للجراحة، والمتخصص في العلاج الجراحي للكيس المائي للكبد (kyste hydatique du foie) العلاج الجذري. كان لهذا الممارس الإستشفائي النابغ، المعروف بنظاله، سيرة ذاتية أكاديمية ممتازة أثناء وبعد حرب التحرير الوطنية.
            </p>
            <p class="mt-2">
                أستاذ في الجراحة العامة، شارك بشير منتوري في إنتاج العديد من المنشورات العلمية ذات أهمية بالغة للعلوم الطبية * حيث أن بعض أعماله ومنشوراته تبين منهجية أصلية بمثابة ثورة في العلاج الجراحي للأكياس المائية. ساهم البروفيسور بشير منتوري في ذلك من خلال وصف تقنية «الحذف الجواري للكيس» والتي تتضمن إزالة الكيس بأكمله تاركًا في مكانه شريحة من الكبد السليم، دون تجويف متبقي. بعد هذه العملية الخاصة، لم يتبقى أي تجويف إلا شريحة كبدية لينة لتجنب المضاعفات المتواجدة في العمليات الإحترازية وكذا العودة إلى الداء الكبدي الناتج من تبرعم اليرقات المتبقية.
            </p>
            <p class="mt-2">
                بالإضافة إلى ذلك، في هذا الابتكار، بعد إزالة الكيس المائي، يتم تطبيق حل لعامل مثبت (محلول الفورمالين...) على مستوى الجواري الكبدي المصاب وبالتالي تدمير أي بقايا ليرقات مجهرية.
            </p>

            <p class="mt-2">
                هذه الطريقة نالت تأييد العديد من المؤلفين لاسيما بيلي وآخرون (1987) بورجيون وآخرون(1979)، بيرنز وهيردين (1991)، زاوش وحوات (2006)
            </p>
        </div>

        <h4><span>منشوراته</span></h4>

        <div style="background-color: #E7E9EB!important;" class="mb-5 p-3">
            <div class="" dir="ltr">
                <ul>
                    <li>Seror J, Mentouri B, Issad H. “On the association of duodenal ulcer and dolichocolon”. Ann Chir 1968, 22: 767-72. </li>
                    <li>Seror J, Mentouri B, Daoud K, Vedrenne C. « Biliary lithiasis and hydatid cysts of the liver. Experimental research and clinical studies ». Ann Chir 1968, 22: 1269-77.  </li>
                    <li>Seror J, Bendib M, Toumi M, Piva G, Boudjellab A, Mentouri B, Guedj P. « Carboxyangiography in subhepatic venous exploration ». Mem Adcad Chir 1969, 95: 746-51.  </li>
                    <li>Seror J, Mentouri B, Issad H. « Cold tuberculous abscess of the rectus abdominis sheath ». Ann Chir 1969, 23: 1261-5.  </li>
                    <li>Seror J, Mentouri B, Meradj B. « Bilobed gallbladder of congenital origin ». Mem Adcad Chir 1969, 95: 41-7. </li>
                    <li>Daoud K, Djilali G, Zitouni M, Salem A, Hamani A, Roche P, Mentouri B. « Results of the surgical treatment of gastroduodenal ulcer (apropos of series of 782 operated cases) ». Ann Gastroenterol Hepatol 1973, 9: 19-36.  </li>
                    <li>Mentouri B, Daoud K, Haroud M, Hammani A. « Surgical treatment of liver hydatid cysts. Apropos of a series of 381 cases ». Helv Chir Acta 1974, 41: 675-81.  </li>
                    <li>Mentouri B, Roche P, Hammani A, Hammad A. « Oddi’s sphincterotomy. Indications and results : 445 cases ». Chirurgie 1975, 101: 877-81.  </li>
                    <li>Mentouri B, Roche P, Hammani A, Hammad A, Meradji B. « Role of pericystectomy in the treatment of hydatid cysts of the liver ». Chirurgie 1975, 101: 882-4. </li>
                    <li>Zmerli S, Ayed M, Mentouri B, Salem A. « Diagnostic and therapeutic consedirations based upon 65 cases of hydatid cyst of the kindey (author’s transI) ». Chirurgie 1978, 104: 425-30. </li>
                </ul>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Mentouri"
    }
</script>

<style scoped>
    .mentouri h4 span{
        font-size: 35px;
        margin-left: 10px
    }
    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;
    }

    .mentouri{
        font-family: DroidArabic!important;
    }
    .mentouri p{
        font-size: var(--s-font-size);
    }
    .mentouri h6{
        font-size: var(--s-font-size);
    }
    .mentouri h3{
        margin-bottom: 25px;
        font-size: var(--l-font-size);

    }
    .mentouri h4 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
        font-size: var(--ml-font-size);

    }
    .mentouri h3 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .mentouri h4{
        margin-top: 20px;
        margin-bottom: 20px;
    }
</style>