<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('studies.title') }} > {{ $t('studies.monkeypox.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="title ">جدري القردة</div>

        <div class="monkey">
            <h5 style="margin-bottom: 20px">معلومات عامة حول جدري القردة:</h5>

            <h6><span>ما هو جدري القردة؟</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    جدري القردة هو مرض فيروسي حيواني المصدر (zoonose)، أي أنه يمكن أن ينتقل من الحيوان إلى البشر إضافة إلى انتقاله بين البشر، وتشبه أعراضه أعراض الجدري البشري إلا أنه أقل خطورة. يتواجد هذا المرض بشكل شائع في الغابات الاستوائية لوسط وغرب إفريقيا حيث تعيش الحيوانات الحاملة للفيروس، ونادرا ما يتم تحديد أشخاص مصابين بهذا المرض خارج هذه المناطق.
                </p>
            </div>

            <h6><span>ما هي أعراض جدري القردة؟</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    بعد فترة حضانة تدوم من 6 إلى 16 يوم، تبدأ أعراض المرض بظهور الحمى، الصداع الشديد، انتفاخ الغدد اللمفاوية وآلام الظهر والعضلات. كما يبدأ الطفح الجلدي عادة في غضون يوم إلى ثلاثة أيام من بداية الحمى ويتركز في الوجه وراحتي اليدين وباطن القدمين. يتطور الطفح الجلدي في حوالي عشرة أيام من آفات قاعدية مفلطحة إلى بثور صغيرة مملوءة بسائل لتشكل قشورا في الأخير.
                </p>
                <p>
                    تدوم هذه الأعراض عادةً ما بين أسبوعين إلى أربعة أسابيع وتختفي في معظم الأحيان من تلقاء نفسها دون علاج.
                </p>
            </div>

            <h6><span>كيف ينتقل المرض؟</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    يمكن أن ينتشر جدري القردة من الحيوانات الى البشر عند التلامس مع الحيوان المصاب، اذ تعتبر القوارض والقرود من حاملي المرض الأساسية. حيث ينصح بتجنب الاتصال الغير المحمي بالحيوانات البرية، خاصة المريضة أو الميتة.
                </p>
                <p>
                    اما عن العدوى من شخص لآخر فإنها تتم من خلال الاتصال الجسدي الوثيق، عن طريق الطفح الجلدي وسوائل الجسم. فقد ينتقل المرض من خلال الملابس أو الفراش أو المناشف أو أشياء مثل أواني الطعام الملوثة بالفيروس. يمكن للفيروس أيضًا أن ينتشر من امرأة حامل إلى الجنين عبر المشيمة، أو من المرأة المصابة إلى الطفل أثناء الولادة أو بعدها عن طريق ملامسة الجلد للجلد.
                </p>
            </div>

            <h6><span>كيفية الوقاية من مرض جدري القردة</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <div class="p-3">
                    <ul>
                        <li>
                            تجنب الاختلاط بالأشخاص الذين يُشتبه في إصابتهم بجدري القردة أو تأكدت إصابتهم به.
                        </li>
                        <li>
                           تنظيف اليدين بانتظام بالماء والصابون أو بمطهر كحولي.
                        </li>
                        <li>
                           ارتداء قناعً طبي وحث المصابين او الذين يُشتبه في إصابتهم بارتدائه ايضا مع الالتزام بالعزلة خاصةً إذا كانوا يسعلون أو لديهم بثور في الفم. </li>
                        <li>
                           	في حالة وجوب مخالطة شخص مصاب بجدري القردة (مثل عمال الصحة) يجب التحلي بكل إجراءات الحماية والاحتياطات الصحية. </li>
                        <li>
                            عند وجود حالة إصابة في البيت، يتوجب:
                            <ul>
                                <li>
                                   	ارتداء قناع واستخدام قفازات احادية الاستعمال عند لمس أي ملابس أو فراش يخص المصاب.
                                </li>
                                <li>
                                   غسل ملابس الشخص ومناشفه وأغطية الأسرة وأواني الطعام بالماء الدافئ والمنظفات بالإضافة الى تطهير وتعقيم أي أسطح ملوثة.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <h6><span>كيفية التصرف في حالة اعتقاد الإصابة بمرض جدري القردة</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    في حالة الاعتقاد بالإصابة من خلال اتصال وثيق بشخص مصاب او ظهور اعراض المرض، يجب استشارة الطبيب لإجراء الاختبارات اللازمة وتلقي الرعاية الطبية مع العزل الفوري وتجنب الاختلاط مع الآخرين.
                </p>
            </div>

            <h6><span>ماذا عن اللقاح؟</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    توجد العديد من اللقاحات للوقاية من الجدري والتي توفر أيضًا بعض الحماية ضد جدري القردة لكن معظمها لم يعد متاحا لعامة الناس، وذلك لكون الجدري البشري أول مرض معدي تم القضاء عليه بفضل التلقيح حيث انتهى التطعيم ضد هذا الوباء منذ الثمانينات.</p>
                <p>
                    قد تمت الموافقة على لقاح جديد تم تطويره للقضاء على الجدري في عام 2019 لاستخدامه في الوقاية من جدري القردة لكنه ليس متاحاً على نطاق واسع بعد. وتعمل منظمة الصحة العالمية مع الشركة المصنعة لتحسين مستويات إتاحته.
                </p>
            </div>


            <div class="title body">معلومات موجهة لمستخدمي الصحة:</div>

            <h6>تصنف الحالات المشتبه بها، المحتملة، المؤكدة وكذا السلبية على النحو التالي:</h6>

            <div class="text-center">
                <img src="../assets/etudes/tableau_sante.png" class="img-thumbnail" alt="...">
            </div>

            <h6><span>التشخيص</span></h6>
            <!--<div class="p-3 mb-4" style="background-color: #E7E9EB!important;">-->
            <!--</div>-->

            <!--<h6><span>العينات الموصي بها للتشخيص الفيروسي للعدوى بفيروس جدري القردة</span></h6>-->
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <div class="p-3">
                    <p>في حالة افتراض وجود جدري القرود، يكون تأكيد التشخيص بيولوجيًا عن طريق تحديد الفيروس، لا سيما عن طريق اختبار PCR.</p>

                    <p>
                        تعتبر حاليا ال PCR التقنية الأساسية المعتمد عليها للتشخيص، حيث لا ينبغي استخدام زراعة الخلايا كتشخيص من الدرجة الأولى.  فيما يخص العينات الموصى بها للتشخيص الفيروسي للعدوى بالمرض فهي تختلف وفق السياق المرضي و / أو نوع الاضرار التي تتم ملاحظتها وهي كما يلي:
                    </p>

                    <ul>
                        <li>عينات جلدية مخاطية: استئصال نسيج متضرر على مستوى الجلد و / او الغشاء المخاطي خارج ساحة الأنف والأذن والحنجرة؛</li>
                        <li>عينات الأنف والأذن والحنجرة: مسحات فموية بلعومية، او أنفية بلعومية؛</li>
                        <li>عينات الجهاز التنفسي: نصف عميقة او عميقة في حالة وجود علامات تنفسية؛</li>
                        <li>السائل النخاعي: في حالة الاشتباه في اصابة عصب.</li>
                    </ul>
                </div>
            </div>

            <h6><span>الإجراء الموصى به:</span></h6>
            <p>خطط الاجراءات المتبعة</p>
            <div class="text-center mb-4">
                <img src="../assets/etudes/suivi.png" class="img-thumbnail" alt="...">
            </div>

            <h6><span>التوجيهات المفصلة في حالة وجود حالات إصابة بالفيروس</span></h6>
            <div class="p-3 mb-4 col-12 row" style="background-color: #E7E9EB!important;">
                <div class="col-md-12 col-lg-6 p-3 mb-3">
                    <h6 class="fw-bold"><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 5px;" /> - في حالة العزل المنزلي</h6>
                    <ul>
                        <li>التنظيف والتطهير (الملاءات، الأسطح، النفايات، إلخ.)؛</li>
                        <li>علاج الأعراض (خافضات الحرارة والمسكنات)؛</li>
                        <li>التغذية الكافية وشرب الماء؛</li>
                        <li>علاج الآفات الجلدية بما يناسب خطورتها؛</li>
                        <li>إبلاغ المريض بالمضاعفات التي تتطلب رعاية عاجلة.</li>
                    </ul>
                </div>
                <div class=" col-md-12 col-lg-6 p-3">
                    <h6 class="fw-bold" ><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 5px;" />  - في حالة العلاج في المستشفى</h6>
                    <ul>
                        <li>ارتداء قناع إلزامي (ضد القطرات والتلوث الجوي)؛</li>
                        <li>تنظيف وتطهير جميع المناطق والمواد الملوثة؛</li>
                        <li>معالجة سوائل الجسم والمخلفات الصلبة والمعدية؛</li>
                        <li>الاحترام الصارم للعزل؛</li>
                        <li>خطة للتقييم الطبي ومتابعة العاملين الصحيين المعرضين.</li>
                    </ul>
                </div>
            </div>

            <h6><span>جدري القردة، فيروس متحور؟</span></h6>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>لا يمكن اعتبار الفيروس المنتشر حاليا فيروسا متحورا. حيث أظهرت تحاليل الحمض النووي علاقة وراثية وطيدة داخل فرع غرب إفريقيا، مما يؤكد السلالة الأفريقية الأصلية (في انتظار التحليل الجيني لعدد أكبر من المرضى).</p>
            </div>

            <h6><span>مراجع:</span></h6>
            <div dir="ltr" class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    Variole du singe ‎‎(Orthopoxvirose ‎simienne)‎. Organisation mondiale de la santé. Mai 2022.
                    <a href="https://www.who.int/fr/news-room/fact-sheets/detail/monkeypox" target="_blank">https://www.who.int/fr/news-room/fact-sheets/detail/monkeypox</a>
                </p>
                <p>
                    A. Antinori et al., « Epidemiological, clinical and virological characteristics of four cases of monkeypox support transmission through sexual contact ». Eurosurveillance, Volume 27, Issue 22, 02/Jun/2022
                    <a href="https://www.eurosurveillance.org/content/10.2807/1560-7917.ES.2022.27.22.2200421" target="_blank">https://www.eurosurveillance.org/content/10.2807/1560-7917.ES.2022.27.22.2200421</a>
                </p>
                <p>
                    J. Isidro et al., « Multi-country outbreak of Monkeypox virus: genetic divergence and first signs of microevolution », Monkeypox/Genome Reports – Virological.  May 2022.
                    <a href="https://virological.org/t/multi-country-outbreak-of-monkeypox-virus-genetic-divergence-and-first-signs-of-microevolution/806" target="_blank"> https://virological.org/t/multi-country-outbreak-of-monkeypox-virus-genetic-divergence-and-first-signs-of-microevolution/806</a>

                </p>
                <p>
                    Clinical management and infection prevention and control for monkeypox: Interim rapid response guidance. World Health Organization. June 2022.
                    <a href="https://www.who.int/publications/i/item/WHO-MPX-Clinical-and-IPC-2022.1" target="_blank">https://www.who.int/publications/i/item/WHO-MPX-Clinical-and-IPC-2022.1</a>
                </p>
                <p>
                    Avis relatif aux mesures de prévention des risques de transmission du virus Monkeypox par les produits sanguins labiles, les greffons et autres éléments du corps humain (9 juin 2022). Haut Conseil de la santé publique.
                    <a href="https://www.hcsp.fr/explore.cgi/avisrapportsdomaine?clefr=1217" target="_blank">https://www.hcsp.fr/explore.cgi/avisrapportsdomaine?clefr=1217</a>
                </p>
                <p>
                    Monkeypox case report form. World Health Organization. June 14, 2022.
                    <a href="https://www.who.int/publications/i/item/WHO-MPX-Clinical-CRF-2022.1" target="_blank">https://www.who.int/publications/i/item/WHO-MPX-Clinical-CRF-2022.1</a>
                </p>
                <p>
                    Our World in Data. <a href=" https://ourworldindata.org/explorers/monkeypox" target="_blank"> https://ourworldindata.org/explorers/monkeypox</a>
                </p>
                <p>
                    Société Française de Microbiologie : Gestion des prélèvements biologiques d’un patient suspect ou confirmé d’infection par le Monkeypox virus :
                    <a href="https://www.preventioninfection.fr/document/11-06-2022-gestion-des-prelevements-biologiques-dun-patient-suspect-ou-confirme-dinfection-par-le-monkeypox-virus-mpxv/" target="_blank"> https://www.preventioninfection.fr/document/11-06-2022-gestion-des-prelevements-biologiques-dun-patient-suspect-ou-confirme-dinfection-par-le-monkeypox-virus-mpxv/</a>
                </p>
                <p dir="rtl">

                    جدري القردة. منظمة الصحة العالمية:                     <a href="https://www.who.int/ar/news-room/questions-and-answers/item/monkeypox" target="_blank"> https://www.who.int/ar/news-room/questions-and-answers/item/monkeypox</a>
                </p>


            </div>


        </div>
    </div>
</template>

<script>
    export default {
        name: "monkey"
    }
</script>

<style scoped>
    .monkey h6 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .monkey p{
        font-size: var(--s-font-size)!important;
    }
    .monkey{
        font-family: DroidArabic!important;
    }
    .monkey ul li{
        font-size: var(--s-font-size)!important;
    }
    .monkey h3{
        margin-bottom: 35px;
    }
    .monkey h3 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .monkey h6{
        margin-bottom: 20px;
    }
</style>