<template>

    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <div class="activities mt-4">
            <h4><span>|</span>مشاركة الوكالة الوطنية للأمن الصحي في المؤتمر الوطني العاشر لعلم المناعة</h4>
            <div class="date">
                <span> 25 جوان 2022</span>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../assets/activities/20220625.jpg" class="img-thumbnail" alt="...">
                </div>
                <div class="col-md-6">
                    <img src="../../assets/activities/2022062502.jpg" class="img-thumbnail" alt="...">
                </div>
            </div>
            <div class="description">
                <p class="mt-3">
                    بإفتتاح من طرف البروفسور كمال جنوحات والبروفسور كمال صنهاجي، عرف المؤتمر الوطني لعلم المناعة المنظم من طرف الجمعية الجزائرية لعلم المناعة(SAI)  والمنعقد بفندق أوراسي بالجزائر العاصمة في دورته العاشرة) 24-25 جوان 2022) إقبال كبير من المجتمع العلمي نظرا لتنوع وثراء البرنامج المقترح.
                </p>
                <p class="mb-4">
                    في سياق علم المناعة، في ظل انتشار وباء جدري القردة وفي إطار دور الوكالة، قدم رئيس المجلس العلمي البروفيسور صديق آيت مسعودين خلال تدخله معلومات عامة حول الوباء متضمنتا: الأعراض، كيفية التنقل، والتشخيص الملائم. بعد التطرق إلى الإحصاءات العالمية حول تطور وانتشار هذا الوباء، أولى رئيس المجلس العلمي أهمية للتوجيهات التي يجب إتباعها من طرف الطاقم الطبي والتأهب لظهور أي إصابة موافقة لتعريف المنظمة الصحة العالمية.
                </p>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        name: "Act-02"
    }
</script>

<style scoped>
    .activities{
        font-family: DroidArabic!important;

    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 30px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>