import { createApp } from 'vue'
import App           from './App.vue'
import i18n          from './locales/i18n'
import routes        from './routes/routes'

// Import jw-vue-pagination
import JwPagination from 'jw-vue-pagination'

import Paginate from "vuejs-paginate-next"

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { fab }  from '@fortawesome/free-brands-svg-icons'

import { fas }  from '@fortawesome/free-solid-svg-icons'

import VueMeta from 'vue-meta';

/* add icons to the library */
library.add(fas,fab);
createApp(App)
    .use(i18n)
    .use(routes)
    // .use(VueMeta )
    // .use(JwPagination)
    .use(Paginate)
    .component('font-awesome-icon', FontAwesomeIcon)
    // .component('jw-pagination', JwPagination)
    .mount('#app');
