<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('studies.title') }} > {{ $t('studies.covid.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <div class="title">مرض فيروس كورونا (كوفيد-19)</div>

        <div class="corona">
            <div class="text-center mb-5">
                <a href="">
                    <img src="../assets/etudes/covid.png" alt="" class="img-thumbnail">
                </a>
            </div>

            <h4><span><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 20px;" />- نظرة عامة</span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    فيروسات كورونا هي عائلة كبيرة من فيروسات الجهاز التنفسي، يسبب بعضها أمراضًا أقل من غيرها، حيث إنها تتراوح من نزلات البرد (الزكام الشائع) إلى أمراض أخرى أشد مثل متلازمة الشرق الأوسط التنفسية (MERS) والمتلازمة التنفسية الحادة الوخيمة (SARS).
                </p>
                <p>
                    مرض   COVID-19 هو مرض معد يسببه فيروس(SARS-CoV-2)، وهو آخر فيروس تم اكتشافه من سلالة فيروسات كورونا، تم التعرُّفُ عليه لأول مرة في مدينة ووهان الصينية في أواخر عام 2019، وأعلنت منظمة الصحة العالمية أنه قد أصبح جائحة عالمية في مارس 2020.
                </p>
            </div>

            <h4><span><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 20px;" />- الأعراض والمضاعفات</span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    قد تظهر مؤشرات مرض فيروس COVID-19 وأعراضه بعد 2 إلى 14 يومًا من التعرُّض له. وتسمى هذه الفترة التي تلي التعرُّض للفيروس وتسبق ظهور الأعراض بفترة الحضانة. يمكن نشر عدوى فيروس COVID-19 قبل ظهور الأعراض. وقد تشتمل مؤشرات المرض والأعراض الشائعة: الحُمَّى، السعال، والشعور بالتعب. كما تتضمن الأعراض المبكرة لفيروس COVID-19 فقدان حاسة التذوق أو الشم. أما الأعراض الأخرى تتمثل في ضيق أو صعوبة في التنفس، آلام في العضلات، القشعريرة، التهاب الحلق، سيلان الأنف، الصداع، ألم الصدر، احمرار العين (التهاب المُلتحِمة)، الغثيان، القيء، الإسهال والطفح الجلدي
                </p>
                <p>
                    على الرغم من أن أعراض معظم المصابين ب COVID-19 تتراوح بين خفيفة إلى معتدلة، يمكن أن يسبب المرض مضاعفات شديدة وأن يؤدي إلى الوفاة في بعض الحالات. إن كبار السن أو من لديهم مشاكل صحية هم الأكثرُ عرضة للإصابة بالمرض الشديد عند العدوى ب COVID-19. يمكن أن تتضمن المضاعفات ما يلي: التهاب الرئة ومشاكل التنفس، فشل عدة أعضاء في الجسم، مشاكل القلب، حالة رئوية حادة تؤدي إلى انخفاض كمية الأكسجين القادمة من خلال مجرى الدم نحو أعضائه (متلازمة الضائقة التنفسية الحادة)، الجلطات الدموية، إصابة حادة بالكلى، والتهابات فيروسية وبكتيرية إضافية.
                </p>
            </div>

            <h4><span><font-awesome-icon icon="fa-solid fa-3" style = "margin-left: 20px;" />- انتشار المرض </span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    ينتشر المرض بشكل أساسي من شخص إلى شخص عن طريق القُطيرات الصغيرة التي يفرزها الشخص المصاب ب COVID-19من أنفه أو فمه عندما يسعل أو يعطس أو يتكلم. وهذه القطيرات وزنها ثقيل نسبياً، فهي لا تنتقل إلى مكان بعيد وإنما تسقط سريعاً على الأرض. ويمكن أن يصاب الأشخاص بمرض COVID-19 إذا تنفسوا هذه القُطيرات من شخص مصاب بالفيروس. وقد تحط هذه القطيرات على الأشياء والأسطح المحيطة بالشخص، مثل الطاولات ومقابض الأبواب. ويمكن حينها أن يصاب الشخص بالمرض عند ملامسته هذه الأشياء أو الأسطح ثم لمس الأعين أو الأنف أو الفم.
                </p>
            </div>

            <!--<h4><span><font-awesome-icon icon="fa-solid fa-4" style = "margin-left: 20px;" />- انتشار المرض</span> </h4>-->
            <!--<div class="p-3 mb-4" style="background-color: #E7E9EB!important;">-->
                <!--<p>ينتشر المرض بشكل أساسي من شخص إلى شخص عن طريق القُطيرات الصغيرة التي يفرزها الشخص المصاب بكوفيد-19 من أنفه أو فمه عندما يسعل أو يعطس أو يتكلم. وهذه القطيرات وزنها ثقيل نسبياً، فهي لا تنتقل إلى مكان بعيد وإنما تسقط سريعاً على الأرض. ويمكن أن يلقط الأشخاص مرض كوفيد-19 إذا تنفسوا هذه القُطيرات من شخص مصاب بعدوى الفيروس. وقد تحط هذه القطيرات على الأشياء والأسطح المحيطة بالشخص، مثل الطاولات ومقابض الأبواب. ويمكن حينها أن يصاب الناس بالعدوى عند ملامستهم هذه الأشياء أو الأسطح ثم لمس أعينهم أو أنفهم أو فمهم.</p>-->
            <!--</div>-->

            <h4><span><font-awesome-icon icon="fa-solid fa-4" style = "margin-left: 20px;" />- فحوصات تشخيص COVID-19</span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    في فحوصات COVID-19 يتم البحث عن فيروسSARS-CoV-2 (أو بشكل أكثر دقة عن المادة الوراثية له) في تجويف الفم والأنف وكذا مضادات الفيروس في الدم.
                </p>

                <div class="p-4">
                    <h6><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 5px;" />-
                        <strong> PCR فحص   </strong>
                        يتيح اختبار تفاعل البوليميراز المتسلسل تحديد ما إذا كنت حاملًا للفيروس في وقت أخذ العينات، وهو الاختبار المرجعي الأكثر حساسية لتشخيص العدوى.
                    </h6>
                    <h6><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 5px;" />-
                        <strong> فحص مولد المضادات
                            (Antigène)
                        </strong>
                        يتطلب اختبار المستضد السريع نفس عينة البلعوم الأنفي مثل فحص PCR ولكن تقنية تحليل مبسطة. إنه أقل حساسية وذو فعالية فقط إذا كان المريض يعاني من أعراض مرتبطة بـ COVID-19
                    </h6>
                    <h6><font-awesome-icon icon="fa-solid fa-3" style = "margin-left: 5px;" />-
                        <strong> فحص المصل
                            (Sérologie)
                        </strong>
                        يكمن هذا الاختبار في فحص دم وله دور مختلف تمامًا حيث يبحث عن وجود الأجسام المضادة ويتيح معرفة ما إذا كان المريض على اتصال بالفيروس في الماضي ويظهر رد فعل مناعي ضد فيروس SARS-CoV-2 .
                    </h6>

                </div>
            </div>

            <h4><span><font-awesome-icon icon="fa-solid fa-5" style = "margin-left: 20px;" />- الوقاية من المرض </span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <div class="p-4 mb-2">
                    <ul>
                        <li>
                            	التطعيم واحترام جدول التلقيح الكامل.
                        </li>
                        <li>
                            	الخضوع إلى اختبارات كشف المرض والعزل في حالة تأكده.
                        </li>
                        <li>
                            	الحفاظ على التباعد الجسدي بمسافة لا تقل عن مترين والحدّ من الاتصال المباشر مع الآخرين قدر الإمكان.
                        </li>
                        <li>
                           	تجنب الحشود والتجمعات.
                        </li>
                        <li>
                           	الالتزام بارتداء الكمامة وتجنب ملامسة العينين والفم والأنف.
                        </li>
                        <li>
                           	تنظيف وتعقيم الأشياء والأسطح التي تتعرض للّمس بشكل متكرر.
                        </li>
                        <li>
                           	تهوية الأماكن المغلقة.
                        </li>
                        <li>
                            	غسل اليدين كثيرًا بالماء والصابون لمدة 20 ثانية على الأقل.
                        </li>
                        <li>
                           	استخدام مطهر الأيدي ذو أساس كحولي يحتوي على الكحول بنسبة 60٪ على الأقل إذا لم يتوفر الماء والصابون.
                        </li>
                        <li>
                           	تغطية الفم الأنف بمنديل عند السعال أو العطس.
                        </li>
                        <li>
                           	تجنب الاختلاط بالمرضى المصابين بأعراض تنفسية.
                        </li>
                        <li>
                           	عدم السفر عند وجود أعراض مرضية.
                        </li>
                    </ul>
                </div>
            </div>

            <h4><span><font-awesome-icon icon="fa-solid fa-6" style = "margin-left: 20px;" />- الوضعية الوبائية في الجزائر </span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <p>
                    في الجزائر، مند 3 جانفي 2020 إلى غاية 27 جوان 2022، تم تسجيل 266049 حالة مؤكدة من COVID-19 مع 6875 حالة وفاة، حسب مصادر المنظمة العالمية للصحة. اعتبارًا من 19 جوان 2022، تم إعطاء ما مجموعه 15205854 جرعة لقاح ضد فيروس كورونا مع 6 851 660 شخص الذين تم تطعيمهم بالكامل ما يقابل 15.9 % من مجموع السكان المستهدفين (18 سنة وما فوق
                    ).
                </p>
                <p>
                    ظهرت المتغيرات الأولى المثيرة للقلق (VOC) من نهاية عام 2020. نذكر متغير Alpha ومتغيرات BetaوGamma ومتغيرDelta ومتغير Omicron هو آخر المركبات العضوية المتطايرة التي ظهرت.
                </p>
                <p>
                    حيث أصيب جزء كبير من السكان البالغين بفيروس  COVID-19والغالبية العظمى كانت في يناير 2022 بالتزامن مع اكتشاف المتحورOmicron -B.1.1.529- ثم لوحظ انخفاض تدريجي ومعتبر. ومع ذلك، لا يزال الوضع مقلقًا لأن تداول فيروس SARS-CoV-2 يستمر في التقدم بظهور السلالة الفرعية BA.2 لمتغير  Omicronومؤخرا لوحظ استبدال تدريجي لـ BA.2 مع زيادة في الكشف عن BA.5وBA.4 في العديد من البلدان الأجنبية، ولا سيما في جنوب افريقيا، البرتغال، إنجلترا، الولايات المتحدة الأمريكية، ألمانيا، فرنسا...
                </p>
                <p>
                    في مواجهة جائحة COVID-19، يعد التلقيح من أهم الإجراءات الرئيسية لاحتواء الوباء وحماية السكان بالخاصة للوقاية من الأشكال الخطيرة للمرض، العلاج بالمستشفيات ولا سيما من الوفاة. لذلك يجب تكثيف جهود التلقيح لتحسين التغطية بشكل أسرع، لا سيما الجرعات المنشطة لدى الأشخاص المؤهلين خاصة المصابين بالأمراض المزمنة أو بالقصور المناعي.
                </p>
                <p>
                    تستمر لقاحات COVID-19 الحالية، المستندة إلى السلالة الاصلية من SARS-CoV-2، في إظهار حماية ضد الأشكال الخطيرة والوفاة لجميع متحورات الفيروس التي لوحظت حتى الآن. وتشير منظمة الصحة العالميةOMS  إلى أن ظهور متحورات مثيرة للقلق أدى إلى انخفاض الحماية. لذلك توصي بتقييم ما إذا كانت اللقاحات المضادة لـ COVID-19 المكيفة مع المتحورات )ولا سيما Omicron) ستكون أكثر فعالية.
                </p>
                <p>
                    في هذا السياق، يظل التلقيح وتطبيق إيماءات الحاجز ضروريين للحد من انتشار الوباء وحماية الأشخاص الأكثر ضعفاً. احترام التدابير الأخرى الموصى بها أمر ضروري أيضًا في حالة ظهور الأعراض أو الاختبار الإيجابي أو الاتصال المعرض للخطر.
                </p>
            </div>

            <h4><span>للمزيد من المعلومات:  </span> </h4>
            <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                <ul>
                    <li>
                        <a href="https://www.who.int/countries/dza/" target="_blank"> https://www.who.int/countries/dza</a>
                    </li>
                    <li>
                        <a href="https://pasteur.dz/fr/dz" target="_blank">https://pasteur.dz/fr/dz</a>
                    </li>
                    <li>
                        <a href="https://www.sante.gov.dz/coronavirus/coronavirus-2019.html" target="_blank">https://www.sante.gov.dz/coronavirus/coronavirus-2019.html</a>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Corona"
    }
</script>

<style scoped>
    .corona{
        font-family: DroidArabic!important;
    }
    .corona h3{
        margin-bottom: 35px;
        font-size: var(--l-font-size);

    }
    .corona h4{
        margin-bottom: 15px;
        font-size: var(--ml-font-size);


    }
    .corona h3 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .corona h4 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .corona p{
        font-size: var(--s-font-size)!important;
    }
    .corona h6{
        font-size: var(--s-font-size)!important;
    }

</style>