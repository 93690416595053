<template>

  <Header />

  <router-view class="view Carousel" name="Carousel"></router-view>
  <router-view class="view Latest-News" name="LatestNews"></router-view>
  <router-view class="view Studies" name="Studies"></router-view>
  <router-view class="view Corona" name="Corona"></router-view>
  <router-view class="view Monkey" name="Monkey"></router-view>
  <router-view class="view ActOne" name="ActOne"></router-view>
  <router-view class="view ActTwo" name="ActTwo"></router-view>
  <router-view class="view ActThree" name="ActThree"></router-view>
  <router-view class="view Biography" name="Biography"></router-view>
  <router-view class="view Biography" name="Missions"></router-view>
  <router-view class="view OrganizationalStructure" name="OrganizationalStructure"></router-view>
  <router-view class="view ScientificBoard" name="ScientificBoard"></router-view>
  <router-view class="view WordPresident" name="WordPresident"></router-view>
  <router-view class="view Legislation" name="Legislation"></router-view>
  <router-view class="view Statement" name="Statement"></router-view>
  <router-view class="view ActFour" name="ActFour"></router-view>
  <router-view class="view HeatWave" name="HeatWave"></router-view>
  <router-view class="view Zerhouni" name="Zerhouni"></router-view>
  <router-view class="view Mentouri" name="Mentouri"></router-view>
  <router-view class="view Belkacem" name="Belkacem"></router-view>
  <router-view class="view Articles" name="Articles"></router-view>
  <router-view class="view Detail_article" name="Detail_article"></router-view>
  <router-view class="view PageNotFound" name="PageNotFound"></router-view>
  <router-view class="view testPagination" name="testPagination"></router-view>

  <Footer />

</template>

<script>

import Header from './components/Header.vue'
import Footer from './components/Footer'

export default {
  name: 'App',
  components: {
      Header,
      Footer
  }
}
</script>

