<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <div class="activities mt-4">
            <h4><span>|</span>60  سنة، إختراعات و إبتكارات</h4>
            <div class="date">
                <span> 04 جويلية 2022</span>
            </div>
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <img src="../../assets/activities/20220705.png" class="img-thumbnail" alt="...">
                </div>
            </div>
            <div class="description">
                <p class="mt-3 mb-2">
                    أدى استعادة السيادة الوطنية وهذا في مدة 60 عاما إلى تفعيل الآليات التي سمحت للجزائر بانتهاج تطويرا نموذجيا من أجل تنمية معتبرة. لطالما ساهم أبناؤها دائما، قبل وبعد الاستقلال وبدون تفان عبر ابتكاراتهم واختراعاتهم في هذه الديناميكية الحضارية الصاعدة دليلا على ماضي أصلي مشهود.
                </p>
                <p class="mb-2">
                    وفي هذا الإطار قررت الوكالة الوطنية للأمن الصحي بالشراكة مع المديرية العامة للبحث العلمي والتطور التكنولوجي تكريم هؤلاء الذين يعتمدون على العلوم، الطب، التكنولوجيا والمعرفة بكل أنواعها من أجل تحقيق أحلامهم، وتستغل الوكالة هذه المناسبة لإبراز شخصياتنا التي نجحت في فرض نفسها في مختلف المجالات لأنهت أعظم ثروة نمتلكها للتجديد والسيادة.
                </p>
                <p class="mb-2">
                    سوف يتم من خلال هاته السلسة، ولمدة ستين يوما نشر بانتظام عن الابتكارات والاختراعات المسجلة بأسماء علماء جزائريين أو التي لها علاقة بالجزائر.
                </p>
                <p class="mb-3">
                    يجدر الذكر أن هذه الخيارات لم تعتمد على أي تصنيف لأنه يوجد غيرها الكثير والأفضل، ولكنها تستحق الذكر لأنها تعكس ميولات أمة غزيرة الإبداع.
                </p>
            </div>

            <div class="row mt-4 mb-5">
                <h5><span>شخصيات</span></h5>
                <div class="col-12 col-sm-6 col-lg-3 mt-4">
                    <div class="card" style="width: 100%;">
                        <img src="/images/zerhouni.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">إلياس زرهوني</h5>
                            <router-link to="/zerhouni" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop" >إقرأ المزيد ...</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 mt-4">
                    <div class="card" style="width: 100%;" >
                        <img src="/images/mentouri.jpg" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">بشير منتوري</h5>
                            <router-link to="/mentouri" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 mt-4">
                    <div class="card" style="width: 100%;" >
                        <img src="/images/habba.png" class="card-img-top" alt="">
                        <div class="card-body">
                            <h5 class="card-title">بلقاسم حبة</h5>
                            <router-link to="/belkacem" class="btn btn-warning" style="font-family: DroidArabic!important;" @click="scrolltop">إقرأ المزيد ...</router-link>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Act-04",
        methods:{
            scrolltop(){
                window.scrollTo(0,0);
            }

        }
    }
</script>

<style scoped>
    .activities h5 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .activities{
        font-family: DroidArabic!important;

    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 30px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>