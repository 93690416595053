<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{ $t('agency.scientificBoard.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl" >
        <div dir="rtl">
            <div class="title" >تكوين المجلس العلمي</div>
        </div>
        <div class="description">
            <p class="mb-2">
                يمثل المجلس العلمي جهاز توجيه استراتيجي، قيادي وعلمي للوكالة. ويبدي رأيه في خصوص كل مسألة تتعلق بمهام الوكالة. ويتشكل المجـــلس العلمي مـــن:
            </p>
            <div v-for="item in scientific">
                <div class="list">{{ item.last_name +' '+ item.first_name +'، '+item.task }}</div>
                <div class="details_act">
                    <p>
                        {{ item.employer }}
                    </p>
                    <div class="text-block" v-html="item.description"></div>
                </div>
            </div>

            <p class="mt-5 mb-2">
                ولقد تم انتخاب البروفيسور محمــد صديق آيت مسعودان رئيسا للمجلس.
            </p>
            <p class="mb-2">
                للتذكير، يتعين أعضـــاء المجـــلس العلمي بمــوجـــب قرار من مديـر ديوان رئاسة الجمهورية، بـناء على اقتـراح من رئيس الوكالة، لمدة أربع (04) سنوات قابلة للتجديد. وتنهى مهامهم حسب الأشكال نفسها.
            </p>
            <p class="mb-5">
                يجتمع المجلس العلمي مرة واحدة، على الأقل، كل ستة (06) أشهر، بناء على استدعاء من رئيسه. ويمكنه، زيادة على ذلك، الاجتماع في دورة غير عادية، بناء على استدعاء من رئيسه، باقتراح من ثلثي (2/3) أعضائه أو بطلب من رئيس الوكالة. تضمن مصالح الوكالة أمانة المجلس العلمي.
            </p>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "ScientificBoard",
        data(){
          return {
              scientific:this.scientific
          }
        },
        created(){
            this.getScientific();
        },
        methods:{
            async getScientific(){
                let url = 'https://backend-nssa.el-mouradia.dz/api/scientific';
                await axios.get(url).then(response =>{
                    this.scientific = response.data.scientific;
                    console.log(this.scientific);
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }
</script>

<style scoped>
    .text-block {
        white-space: pre-line;
        line-height: 30px;
        text-align: justify;
    }
    p{
        text-align: justify;
        margin-bottom: calc(35/16 * var(--default-size-unit));
    }
    li{
        text-align: justify;
        font-weight: 700;
    }
    .list{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--ml-font-size);
        font-weight: 700;
        margin-top: calc(25/16 * var(--default-size-unit));
        margin-bottom: 15px;
    }
    .list::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(18/16 * var(--default-size-unit));
        height: calc(6/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(20/16 * var(--default-size-unit));
        /*margin: calc(18/16 * var(--default-size-unit));*/
    }
    .details_act p {
        /*margin-right: calc(18/16 * var(--default-size-unit));*/
        margin-bottom: 12px!important;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
        padding-top: calc(35/22 * var(--default-size-unit))!important;
        padding-bottom: calc(35/22 * var(--default-size-unit))!important;
    }


</style>