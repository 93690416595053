<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{ $t('agency.presidentWelcome.title') }}</div>
        </div>
    </div>

    <div class="container " dir="rtl">
        <div class="title " >كلمة  الرئيـس</div>

        <div class="row">
            <div class="centre-title" ><font-awesome-icon class="quote" icon="fa fa-quote-right" />&nbsp; الصحة ليست العلاج  </div>
        </div>

        <div class="row" >
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-9  mt-4">

                <p class="back-gray" style="font-family: DroidArabic!important;">الصحة ليست العلاج، بل هي  حالة سلامة جسدية  وراحة نفسية، يجب رعايتها والاهتمام بها من أجل الحفاظ عليها لزمن أطول وتجنب الوصول إلى مرحلة العلاج وبالتالي فوات الأوان، كثيرة ومتنوعة هي الأسباب التي قد تؤدي إلى تدهور صحي (أو مرض)، فبالإضافة  إلى تركيبنا الوراثي الذي يمكن أن يلعب دورا إيجابيا أو سلبيا في حصانة البدن، نحن معرضون إلى عديد المؤثرات التي تمس صحتنا.</p>
                <p class="back-gray" style="font-family: DroidArabic!important;">يمكن توزيع هذه المؤثرات على مستويين: المستوى الأول يتمثل في مجموع العناصر التي تدخل في سلوكياتنا وعاداتنا ولنا القدرة على التحكم بها كالنظام الغذائي، التدخين، تعاطي الكحوليات أو ممارسة الرياضة وهذا ما يعرف بالنمط الحياتي السليم. يعتبر وعي المواطن مرفقا بإعلام هادف ومكيف أهم مفاتيح النجاح من أجل تحقيقه.
                    <br>
                    أما المستوى الثاني فهو أكثر  شمولية حيث يحتوي على عناصر مثل جودة الهواء، المياه المستهلكة، المنتجات الاستهلاكية المستخدمة، الأوبئة، الأدوية والعلاجات المقدمة، المبيدات، الموارد الناتجة عن التكنولوجيات المختلفة وما إلى ذلك والتي تنتج بشكل عام من طرف النشاطات الصناعية وأحيانًا من قبل الأفراد والتي لا يتمتع المواطنون بنفوذ كبير عليها ولكن يمكن مع ذلك تنظيمها من قبل السلطات العمومية. كما يمكن أيضا أن تظهر هذه المؤثرات أثناء الأزمات الكبرى (التلوث الأوبئة، الاحتباس الحراري وما إلى ذلك). يجدر الذكر أنه مهما كانت طبيعة الأزمة، لا يمكن وقوعها دون تراكم سلسلة من الأحداث والظروف.
                </p>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 mt-4">
                <div class="imageflottante">
                    <div class="block-image">
                        <a target="_blank" href="/images/WordPresident.jpg">
                            <img src="/images/WordPresident.jpg"  class="img-thumbnail image" />
                        </a>
                        <div class="overlay-image">البروفسور كمال صنهاجي </div>

                    </div>
                </div>
            </div>


            <div class="col-12">
                <p class="back-gray" style="font-family: DroidArabic!important;">عندما نركز على الصحة بشكل خاص، فمن الممكن اقتراح منهجية مختلفة نوعا ما. هناك نوعان من الأزمات: أزمات صحية "حقيقية" و مخاوف تتعلق بالصحة العامة. حيث يشمل النوع الأول أحداثا ذات طابع وبائي أوإخلالات قد تؤدي للأسف إلى وقوع ضحايا كان يمكن تجنبها، إن الوقوع في هذا النوع من الأزمات يتكرر للأسف أكثر فأكثر.أما النوع الثاني فيتسبب فيه غالبًا عامة الناس ولا ينتمي إلى نفس السجل مع الأزمات الصحية الحقيقية. في الواقع ليس من المؤكد أن لديه أو كان سينتج مرضى، أو أنها مرتبطة بخلل وظيفي حقيقي. يعتبر موقف المواطنين اتجاه التطعيم ضد Covid-19 مثالًا جيدًا على ذلك.

                </p>
                <p class="back-gray" style="font-family: DroidArabic!important;">
                    تشكل الرصد، اليقظة والتنبيه المتعلقة بالسلامة الصحية الركائز الأساسية للوكالة الوطنية للأمن الصحي،والتي تسهر على:
                    <br>
                    1. دراسة المخاطر الصحية وتوقعها من خلال استجابة تسبيقية.
                    <br>
                    2. تقييم المنظومة الصحية وإصلاحها بما يتلاءم مع الصورة الصحية الحقيقة للمواطنين.
                    <br>
                    3. البحث واستكشاف التقنيات والأدوات الحديثة المصاحبة لهذه المهام.
                    <br>
                    وبالتالي، فإن إستخراج وجمع البيانات (data mining)، التحليل والإحالة المرجعية للبيانات الخاصة بالقطاعات المعنية بالمؤشرات التي تؤثر على الصحة والمشار إليها أعلاه أمر ضروري لتوليد مؤشرات جديدة لأول مرة في الجزائر وكذلك لتضخيم الإشارات الضعيفة التي يمكن أن تكون محفزا لإجراء استجابة تسبقية سريعة ومنسقة.
                    <br>
                    كما أن الكم الهائل من البيانات الناتجة عن هذه المهام سوف تحدد صورة صحية حقيقية ومتجانسة مع إصلاح المنظومة الصحية كماً ونوعاً.
                    <br>
                    بالإضافة إلى ذلك، تُستخدم جميع البيانات التي تم جمعها لاستنتاج وحساب مؤشرات الأداء المتعلقة بجودة الرعاية والعناية بالمرضى، على سبيل المثال. وبذلك تساهم بطريقة أساسية في التقييم المستمر للمنظومة الصحية الوطنية.
                    <br>
                    وفي الأخير، تعد البيانات التي تم جمعها ذات قيمة للبحث والإبتكار ، ولا سيما لتطوير محركات الذكاء الاصطناعي. في هذا السياق، هناك العديد من التطبيقات: المساعدة في التشخيص، اكتشاف الأوبئة، وتطوير بروتوكولات علاج جديدة...إلخ. يستخدم الذكاء الاصطناعي الحديث بشكل أساسي تقنيات التعلم الآلي، هذه التقنيات قادرة على التعلم التلقائي الكامل للعلاقات والوظائف "عالية المستوى" فقط من مجموعة من المعطايات(أمثلة)، بإستخدام هذه التقنيات، أصبح من الممكن في وقتنا الحالي القيام بتشخيص آلي بهدف مساعدة الممارس الصحي في اتخاذ قراراته في حالات الأمراض المعقدة أو في النمذجة التنبؤية في مختلف التخصصات الطبية.
                </p>
                <p class="back-orange" style="font-family: DroidArabic!important;">إن المواطنون الأصحاء هم الجهات الفاعلة في التنمية المزدهرة لبلدنا. دعونا نحافظ على أمنها الصحي ضمانًا لأمنها القومي.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WordPresident"
    }
</script>

<style scoped>
    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
        font-family: DroidArabic!important;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;
    }

    .back-gray{
        background-color: #E7E9EB;
        padding: 15px 15px;
    }

    .back-orange{
        background-color: var(--primary-text-color);
        font-size: var(--ml-font-size);
        padding: 15px 15px;
        text-align: center;
        font-weight: 700;
    }

    p{
        /*text-align: justify;*/
        /*margin-bottom: calc(25/16 * var(--default-size-unit));*/
        font-size: var(--m-font-size);
    }
    .block-image {
        position: relative;
        width: 100%;
    }

    .image {
        display: block;
        width: 100%;
        height: auto;
    }

    .overlay-image {
        position: absolute!important;
        top: 88%!important;
        background: rgb(0, 0, 0);
        background: rgba(0, 0, 0, 0.5); /* Black see-through */
        color: #f1f1f1;
        height: 12%;
        width: 100%;
        transition: .5s ease;
        opacity:0;
        color: #f7ffde;
        font-size: var(--m-font-size);
        padding: 7px;
        text-align: center;
    }

    .block-image:hover .overlay-image {
        opacity: 1;
    }
    .imageflottante {
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        .imageflottante{
            width: 100%;
            margin-bottom: 25px;
        }
    }
</style>