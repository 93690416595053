<template>

    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>

    <div class="container" dir="rtl">
        <div class="activities mt-4">
            <h4><span>|</span>مشاركة الوكالة الوطنية للأمن الصحي في تنصيب لجنة تقييم وإعداد قائمة الأدوية الأساسية</h4>
            <div class="date">
                <span> 25 أفريل 2022</span>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../assets/activities/20220425.jpg" class="img-thumbnail" alt="...">
                </div>
                <div class="col-md-6">
                    <img src="../../assets/activities/2022042501.jpg" class="img-thumbnail" alt="...">
                </div>
            </div>
            <div class="description">
                <p class="mt-3">
                    بدعوة من وزارة الصناعة الصيدلانية، شاركت الوكالة الوطنية للأمن الصحي ممثلتا برئيسها البروفسور كمال صنهاجي في تنصيب لجنة الخبراء متعددي التخصصات المكلفة بإبداء الرأي في قائمة الأدوية الأساسية وهذا بعد صدور القرار في الجريدة الرسمية رقم 40 المؤرخة في 25 أفريل 2022.                </p>
                <p class="mb-2">
                    جاءت هذه الدعوة لإظهار أهمية العمل التعاوني بين الوكالة الوطنية للأمن الصحي، وزارة الصناعة الصيدلانية ومختلف القطاعات من أجل تقييم وإعداد قائمة الأدوية الأساسية مع الأخذ بعين الاعتبار عدة معايير منها الفعالية والأمن، المصلحة العمومية، الاحتياط والوفرة الدائمة، التكلفة مع الفعالية.                 </p>

                <p style="margin-bottom: 50px!important;">
                    ترافق الوكالة الوطنية للأمن الصحي هنا المشروع بفضل خبرات مجلسها العلمي وتقاريرها العلمية حول الأدوية المنبثقة من يقظتها الإستراتجية في مجال الأمن الصحي.
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Act-03"
    }
</script>

<style scoped>
    .activities{
        font-family: DroidArabic!important;

    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 30px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
</style>