<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" > {{  $t('agency.title') }} > </div>
        </div>
    </div>

    <div class="container" dir="rtl" >
        <div class="title " >النصوص القانونية</div>

        <div class="row mb-5">
            <div class="px-lg-5 ressource mt-5 mb-5 " >
                <div class="container d-flex pb-5 " style="box-shadow: 5px 5px 30px #5a6268; height: 400px;justify-content: center; flex-direction: column-reverse" >

                    <div class="card-group row " >
                        <div class="cadre col-md-12 col-lg-6  px-lg-5 ">
                            <div class="cadre__inner" >
                                <a href="/pdf/legislation/A2021069.pdf" target="_blank" > A2021069 </a>
                                <a href="/pdf/legislation/A2021069.pdf" download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp; </a>
                            </div>

                        </div>

                        <div class="cadre col-md-12 col-lg-6 px-lg-5">
                            <div class="cadre__inner">
                                <a href="/pdf/legislation/A2021001.pdf" target="_blank">A2021001</a>
                                <a href="/pdf/legislation/A2021001.pdf"  download> <font-awesome-icon class="fa-2x" icon="fa fa-download fa-2x" />&nbsp;</a>
                            </div>
                        </div>


                    </div>

                </div>


            </div>

        </div>

    </div>
</template>

<script>
    export default {
        name: "Legislation"
    }
</script>

<style scoped>
    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;

    }


    @media (max-width: 768px) {
    }
    .card-group .cadre .cadre__inner{
        background-color: #ceded3;
        margin: 10px 20px;
        box-shadow: 5px 5px 20px #051a27;
        height: 78px;
        padding-top: 20px;
        border-radius: 4px;
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        padding-left: 15px;
        padding-right: 15px;
    }
    .card-group .cadre .cadre__inner a{
        color: #373737 !important;
        font-size: calc(60 / 40 * 0.8rem);
        text-decoration: none;
        text-shadow: 0 0 5px rgb(251, 255, 255);
    }
    .card-group .cadre .cadre__inner a:hover,.card-group .cadre .cadre__inner i:hover{
        color: #000000 !important;
        font-weight: bold;
        text-shadow: 0 2px 3px rgb(251, 255, 255);


    }
    .card-group .cadre .cadre__inner i{
        color:gray;
        vertical-align: middle;
        box-shadow: 5px 5px 10px #000000;

    }
    .card-group{
        padding-top: auto;
        padding-bottom: auto;
    }

</style>