<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{ $t('agency.missions.title') }}</div>
        </div>
    </div>

    <div class="container " style="font-family: DroidArabic!important;">
        <div dir="rtl">
            <div class="title" >مهام الوكالة</div>
        </div>
        <div class="row missions">
            <div class="col-12 mt-4" dir="RTL">
                <p dir="RTL">تكلف الوكالة، في إطـــار المـــهام المحددة في المادة&nbsp;<span dir="LTR">&nbsp;3</span>مـن المـــرسوم الرئاسي رقم&nbsp;<span dir="LTR">20</span>&ndash;<span dir="LTR">158</span>&nbsp;المؤرخ في&nbsp;<span dir="LTR">&nbsp;21</span>شوال عام&nbsp;<span dir="LTR"> 1441&nbsp;</span>الموافق 13 جويلية سنة&nbsp;<span dir="LTR">2020</span>&nbsp;والمذكور أعلاه، بما يأتي<span dir="LTR">:</span></p>
                <h6><span>في مجال الأمن الصحي:</span></h6>
                <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                    <ul>
                        <li>التقييم الدوري والخبرة للمخاطر الصحـــية المتعلقة بالمواد الصيدلانية، الأدوية ذات الاســـتعمال البشري أو البيطري، المواد البيولوجية والبيوتكنولوجية، الدراسات العيادية، المستلزمات الصحية، التغذية، الصحة في وسط العمل، الصحة الحيوانية والنباتية، مواد النظافة، مواد التجميل، المياه، البيئة، والسهر على احترام الممارسات الحسنة والمقاييس والمعايير والبروتوكولات المرتبطة بها،</li>
                        <li>التقييم الدوري والإخطار الذاتي فيما يخص المخاطر والتهديدات من كل مصدر على صحة السكان والنظافة العمومية،</li>
                        <li>تنــسيق نشاطات اليـقــظــة الصـحــيـة والــوبــائيــة وإطــلاق الإنذارات المبكرة لتمكين أجهزة تسيير الحالات الاستثنائية من الانتشار في الوقت المناسب، عبر كل التراب الوطني إلى غاية الحدود،&nbsp;</li>
                        <li>تسيير وتنسيق حالات الاستعجال المرتبطة بالمخاطر الصحية، على المستوى الوطني،</li>
                        <li>إقامـة وتطـويـر عـلاقـات التعاون والشراكـة مع الهـيـئـات الأجنبية المماثلة والتنسيق مع المنظمات الدولية المختصة، لا سيما أثناء الجائحة والأزمات الصحية الكبرى وتبادل الوثائق والمعلومات المتعلقة بها،</li>
                        <li>متابعة تطور المــعطيات مع مخــتلف الهياكل المعنية، فيما يخــص تسيــير الاحــتياطات الاستراتيجية من الأدوية والمستهلكات الطبية الجراحية، بوسائل التشخيص ووسائل الحماية المخصصة للتكفل بحالات الاستعجال الصحية والمخاطر الصحية الكبرى،&nbsp;</li>
                        <li>إنجاز دراسات اليقظة والاستشراف والبحث العلمي، وكذا كل التحاليل المساعدة على فعالية السياسة في مجال الأمن الصحي<span dir="LTR">.</span></li>
                    </ul>
                </div>
                <h6><span>في مجال إصلاح المنظومة الوطنية للصحة</span></h6>
                <div class="p-3 mb-4" style="background-color: #E7E9EB!important;">
                    <ul>
                        <li>التفكير، بالتشاور مع الأطراف المعنية،في الطرق والوسائل المثلى لعصرنة المنظومة الوطنية للصــحــة وتحضــيرها لمواجهة التحديات المستقبلية والوقاية منها مثل الأمراض الناشئة، والناشئة من جديد، التهديدات والمخاطر الصحية الجديدة، الأمراض المتنقلة، وغير المتنقلة، والنمو الديموغرافي وتقدم السكان في السن،</li>
                        <li>الجمع المنتظم، لدى الهياكل المعنية، للمعطيات المتعلقة بالوضـــعية الوبائية للبلاد ولمخطط تنظـــيم توزيع العلاج عبر التراب الوطني، وتحيينها،&nbsp;</li>
                        <li>إعداد وإصـــلاح مناهـــج تسيير هياكل العلاج، في إطار تنظيمي وبالتشاور مع الهياكل المعنية، بغرض تحسين نوعية الخدمات الصحية وضمان أكبر قدر من الفعالية والنجاعة في استعمال الموارد المخصصة للقطاع<span dir="LTR">.&nbsp;</span></li>
                    </ul>
                    <p dir="RTL">وتضمن الوكالة، زيادة على ذلك، وظيفة المستشار العلمي لرئيس الجــمــهورية في مجــال الأمــن الصـحي واستراتيجيات إصلاح المنظومة الوطنية للصحة والبرامج الوطنية للصحة العمومية<span dir="LTR">.</span></p>

                </div>

            </div>
        </div>
        <div dir="rtl">
            <div class="title" >الهيكل التنظيمي</div>
        </div>
        <div class="row mb-5">
            <div class="col-12 mt-4">
                <div class="">
                    <a target="_blank" href="/images/organigramme.png">
                        <img width="100%" src="/images/organigramme.png"  class="" />
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Missions"
    }
</script>

<style scoped>
    p{
        margin-bottom: calc(35/16 * var(--default-size-unit));
    }

    .list{
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--m-font-size);
        font-weight: 700;
    }
    .list::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(20/16 * var(--default-size-unit));
        height: calc(6/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(25/16 * var(--default-size-unit));    }

    .missions h6 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
    }
    .missions p{
        font-size: var(--s-font-size)!important;
    }
    .missions ul li{
        font-size: var(--s-font-size)!important;
        line-height: 30px;
    }
    .missions h6{
        margin-bottom: 15px;
    }

</style>