<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('studies.title') }} > {{ $t('studies.aid.title') }}</div>
        </div>
    </div>
    <div class="container mt-4" dir="rtl">
        <!--Aid el-adha-->
        <div class="row">
            <div class="prediction_aid">
                <div class="title">دليل الممارسات الوقائية الصحية التي يجب إتباعها خلال عيد الأضحى</div>

                <p>تغتنم الوكالة الوطنية للأمن الصحي مناسبة عيد الأضحى لاقتراح دليل لصالح المواطنين، الذي يتعلق ببعض التدابير الوقائية التي يجب الأخذ بها عند:</p>
                <h6><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 20px;" />الشراء والاعتناء بأضحية العيد </h6>
                <h6><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 20px;" /> ذبح الأضحية </h6>
                <h6><font-awesome-icon icon="fa-solid fa-3" style = "margin-left: 20px;" />الحفاظ على البيئة </h6>
                <h6><font-awesome-icon icon="fa-solid fa-4" style = "margin-left: 20px;" />استهلاك الأضحية </h6>
                <br>
                <h4><span><font-awesome-icon icon="fa-solid fa-1" style = "margin-left: 20px;" /> - الشراء والاعتناء بأضحية العيد</span> </h4>
                <div class="row p-3">
                    <div class="col-md-8 mb-3 rounded" style="background-color: #E7E9EB!important;">
                        <h6 style="margin-right: 60px; margin-top: 20px">بالإضافة الى استوفاء الشروط الشرعية يجب: </h6>
                        <ul class="list_one mt-4">
                            <li>اقتناء الأضحية من بائع معروف و مكان مخصص لهذه الغاية؛</li>
                            <li>أن تكون سليمة (لا تظهر عليها أي أعراض كالسعال والإسهال والنفخ الغير العادي...الخ)، نشيطة ذو حيوية؛</li>
                            <li>وضعها في مكان امن، مظل وبعيدا عن التيارات الهوائية؛</li>
                            <li>امداد الأضحية بالتغذية المناسبة (تبن- فصة يابسة) وماء نقي إلى غاية اخر 12 ساعة قبل الذبح (حيث يستحسن تقديم الماء فقط حتى تكون الأمعاء غير ممتلئة عند الذبح تفاديا لمرور الجراثيم منها إلى اللحم).</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/etudes/aid.png" class="img-thumbnail" alt="...">
                    </div>
                </div>
                <br>
                <h4><span><font-awesome-icon icon="fa-solid fa-2" style = "margin-left: 20px;" /> - ذبح الأضحية </span></h4>
                <div class="row p-3 m-2 rounded" style="background-color: #E7E9EB!important;">
                    <div class="col-12">
                        <h6 style="margin-right: 60px; margin-top: 20px">قبل الشروع في ذبح الأضحية يجب: </h6>
                    </div>
                    <div class="col-md-6">
                        <ul class="list_two mt-2">
                            <li>اختيار جزار متمكن وطاهر الجسد والثياب؛</li>
                            <li>اختيار مكان مناسب للذبح قرب مصدر ماء نقي؛</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list_two mt-2">
                            <li>تحظير معدات وأدوات مطهرة وتهيئة الأضحية لعملية الذبح؛</li>
                            <li>عدم نحر الأضحية مكتوفة الأطراف؛</li>
                        </ul>
                    </div>
                    <div class="text-center mt-3">
                        <li class="list_two">غسل الذبيحة "موضع النحر" وباقي الاعضاء بماء صالح للشرب.</li>
                    </div>
                </div>
                <br>
                <div class="row m-2">
                    <div class="col-md-8 mb-3 rounded" style="background-color: #E7E9EB!important;">
                        <h6 style="margin-right: 60px; margin-top: 20px">بعد الذبح والاستنزاف الكلي للدم يجب الشروع في عملية السلخ والتي تستوجب:</h6>

                        <ul class="list_two mt-2">
                            <li>فصل الراس ثم الارجل الأمامية؛</li>
                            <li>عدم نفخ الأضحية عن طريق الفم لتجنب تلوث السقيطة بالجراثيم؛</li>
                            <li>تعليق الأضحية لتسهيل عملية السلخ؛</li>
                            <li>عدم استعمال الأرجل لإزالة الجلد مع تجنب غسل السقيطة بالماء؛</li>
                            <li>تجنب ما أمكن تلويث الصوف لسقيطة الأضحية مع التأكد من نظافة مؤخرة السقيطة وإزالة المثانة والمصران المستقيم؛</li>
                            <li>غسل اليدين والمعدات باستمرار لتجنب ما أمكن تلويث السقيطة؛</li>
                            <li>معاينة لون السقيطة (لون وردي الى وردي فاتح = سقيطة سليمة). ان كان اللون اصفر او احمر داكن يجب الاتصال بالمصالح البيطرية المحلية.</li>
                        </ul>
                    </div>
                    <div class="col-md-4">
                        <img src="../assets/etudes/Image1.png" class="img-thumbnail" alt="...">
                    </div>
                </div>
                <br>
                <div class="row m-2 rounded" style="background-color: #E7E9EB!important;">
                    <div>
                        <h6 style="margin-right: 60px; margin-top: 20px">بعد السلخ، يجب المباشرة (اقل من خمس دقائق بعد الذبح) في:</h6>
                    </div>
                    <div class="col-md-6">
                        <ul class="list_two mt-4">
                            <li>استخراج الأحشاء ووضعها في أواني خاصة ونظيفة قبل غسلها؛</li>
                            <li>التخلص من محتوى الجهاز الهضمي بعيدا عن السقيطة لتجنب تلويثها؛</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <ul class="list_two mt-4">
                            <li>تنظيف جوف السقيطة بالماء الصالح للشرب؛</li>
                            <li>معاينة وفحص الاعضاء (الامعاء، المعدة، الرئة، الكبد والقلب) للتأكد من عدم إصابتها ب:</li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="row m-2 rounded" style="background-color: #E7E9EB!important;">
                    <h6 style="margin-right: 60px; margin-top: 20px">الأكياس المائية (Kyste Hydatique): (تصيب الرئة والكبد، وأحيانا القلب)</h6>
                    <div class="col-12">
                        <ul class="list_two">
                            <li>التخلص من الجزء المصاب للعضو إذا تواجد فيه كيسا أو اثنين (مع الحرص من عدم فتقه وتفريغ محتواه) أو إتلاف العضو بأكمله إذا كانت الإصابة بليغة </li>
                        </ul>
                    </div>
                </div>
                <br>
                <div class="row m-2 category text-center">
                    <div class="col-md-3">
                        <img src="../assets/etudes/infecte.png" class="img-thumbnail" alt="...">
                        <h4 class="text-center">رئة مصابة</h4>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/etudes/sein.png" class="img-thumbnail" alt="...">
                        <h4 class="text-center">رئة سليمة</h4>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/etudes/infecte_k.png" class="img-thumbnail" alt="...">
                        <h4 class="text-center"> كبد مصاب</h4>
                    </div>
                    <div class="col-md-3">
                        <img src="../assets/etudes/sein_k.png" class="img-thumbnail" alt="...">
                        <h4 class="text-center">كبد سليم</h4>
                    </div>
                </div>
                <div class="row m-2">
                    <h6 style="margin-top: 20px; margin-right: 10px">يرقة الدودة الشريطية (La larve de Taenia): (الكبد، شحم الأمعاء)</h6>
                    <ul class="list_two" style="margin-right: 30px">
                        <li>تنقية الأعضاء المصابة التي لوحظت فيها بعض نقط بيضاء أو أكياس مائية أو إتلاف العضو بأكمله إذا كانت الإصابة بليغة</li>
                    </ul>
                    <div class="col-md-5">
                        <div style="background-color: #E7E9EB!important;">
                            <h6 style="margin-right: 60px; margin-top: 20px">تلوث الرئة بالدم (écophrage):</h6>
                            <ul class="list_two">
                                <li>التخلص من الجزء الملوث الناتج عن تسرب الدم إلى داخل الرئة عن طريق القصبة الهوائية عند الذبح، وتناول الباقي من دون تخوف.</li>
                            </ul>
                        </div>
                        <div style="background-color: #E7E9EB!important;">
                            <h6 style="margin-right: 60px; margin-top: 20px">ديسطوميا (Distomatose): (الكبد)</h6>
                            <ul class="list_two">
                                <li> تنقية العضو وغسله إذا كانت الإصابة خفيفة أو إتلافه بأكمله وعدم استهلاكه إذا كانت الإصابة بالغة</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div style="background-color: #E7E9EB!important;">
                            <h6 style="margin-right: 60px; margin-top: 20px">دودة الرئة (Strongle des poumons):</h6>
                            <ul class="list_two">
                                <li>تنقية العضو إذ كانت فيه ثلاثة او اربعة حبيبات زرقاء الون (حبيبات البارود) أو إتلافه بالأكمل إذا كانت الإصابة بليغة وتناول باقي الأعضاء والسقيطة دون تخوف</li>
                            </ul>
                        </div>
                        <div style="background-color: #E7E9EB!important;">
                            <h6 style="margin-right: 60px; margin-top: 20px">دودة الرأس (Oestrose):</h6>
                            <ul class="list_two">
                                <li>إذا لوحظت هذه الدودة عند فتح الرأس على طول الخواشم وجب إزالتها</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-3 text-center">
                        <img src="../assets/etudes/estomac.png" class="img-thumbnail" alt="...">
                        <h4 class="text-center" style="background-color: #E7E9EB!important; margin: 5px 0 15px 35px">كبد مصاب</h4>
                    </div>

                    <div class="col-12" style="margin-bottom: 20px; margin-left: 30px; margin-right: 20px!important; background-color: #E7E9EB!important;">
                        <ul class="list_two">
                            <li>دفن الأعضاء المصابة في مكان بعيد عن الحيوانات؛</li>
                            <li>عند اي شك او للحصول على مزيد من المعلومات يرجى الاتصال بالمصالح البيطرية المحلية؛</li>
                            <li>بعد معاينة السقيطة والأحشاء يجب تعليق السقيطة في مكان بارد ونقي لمده خمس ساعات على الأكثر مع تفادي تغطيتها لكي تجف بسرعة.</li>
                        </ul>
                    </div>

                    <h4><span><font-awesome-icon icon="fa-solid fa-3" style = "margin-left: 20px;" /> - الحفاظ على البيئة </span></h4>
                    <div class="col-12" style="margin-bottom: 20px; background-color: #E7E9EB!important; margin-right: 30px">
                        <h6 style="margin-right: 60px; margin-top: 20px">بعد الإنتهاء من عملية الذبح، يجب:</h6>
                        <ul class="list_two">
                            <li>تخصيص اكياس بلاستيكية لجمع النفايات ومحتوى الاحشاء ووضعها في حاوية القمامة؛</li>
                            <li>تنظيف المكان والأدوات المستعملة؛</li>
                            <li>
                                رش الجلد بكمية كافية من الملح والاحتفاظ به بعيدا عن الحرارة. في حالة ما إذا لم يرغب الاحتفاظ به، وجب وضعه في كيس بلاستيكي مفتوح بالقرب من حاوية القمامة دعما لحملة وزارة الصناعة:
                                <a href="https://www.industrie.gov.dz/?%D8%A5%D8%B7%D9%84%D8%A7%D9%82-%D8%AD%D9%85%D9%84%D8%A9-%D8%AC%D9%85%D8%B9,1477" target="_blank" >إطلاق حملة جمع جلود أضاحي عيد الأضحى لسنة 2022</a>
                            </li>
                            <li>منع الشواء قرب الغابات.</li>
                        </ul>
                    </div>

                    <h4><span><font-awesome-icon icon="fa-solid fa-4" style = "margin-left: 20px;" /> - استهلاك الأضحية </span></h4>
                    <div class="col-12" style="margin-bottom: 40px; background-color: #E7E9EB!important; margin-right: 30px">
                        <ul class="list_two">
                            <li>لف ووضع (مع اجتناب التكديس) قطع اللحم التي سيتم استهلاكها (أو التصدق بها)  في ايام العيد الأولى في أكياس او اواني غذائية داخل المبرد لمدة لا تتعدى ثلاثة ايام وأربع درجات مئوية؛</li>
                            <li>لف ووضع (مع اجتناب التكديس) قطع اللحم التي سيتم الاحتفاظ بها في المجمد تحت درجة حرارة ناقص 24 درجة؛</li>
                            <li>للتذكير لا يجب إعادة تجميد قطع اللحوم المجمدة مسبقا.</li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Studies"
    }
</script>

<style scoped>
    .prediction_aid{
        font-family: DroidArabic!important;
    }
    .prediction_aid p{
        font-size: var(--s-font-size);
    }
    .prediction_aid h6{
        font-size: var(--s-font-size);
    }
    .prediction_aid h3{
        margin-bottom: 25px;
        font-size: var(--l-font-size);

    }
    .prediction_aid h4 span{
        background-color: #ffcc00;
        padding: 0 5px 0 5px;
        border-radius: 5px!important;
        font-size: var(--ml-font-size);

    }
    .prediction_aid h3 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .prediction_aid h4{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .list_one{
        line-height: 30px;
        font-size: var(--s-font-size);
    }
    .list_two{
        line-height: 30px;
        font-size: var(--s-font-size);
    }
    .category h4{
        background-color: #E7E9EB!important;
        margin: 6px;
        border-radius: 4px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;

    }
</style>