<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="zerhouni" > شخصيات > إلياس زرهوني</div>
        </div>
    </div>

    <div class="container mt-5 mb-5 zerhouni" dir="rtl">
        <h4><span>|</span>إلياس زرهوني</h4>

        <div class="row">
            <div class="centre-title" ><font-awesome-icon class="quote" icon="fa fa-quote-right" />&nbsp; مبتكر في العلوم الطبية</div>
        </div>

        <div class="row mt-5 mb-5">
            <div class="col-md-6 mb-2">
                <a target="_blank" href="/images/independence.jpg">
                    <img src="/images/independence.jpg" width="300" class="img-thumbnail image float-end float-md-start justify-content-center" />
                </a>
            </div>
            <div class="col-md-6">
                <a target="_blank" href="/images/zerhouni.jpg">
                    <img src="/images/zerhouni.jpg" width="300" class="img-thumbnail image" />
                </a>
            </div>
        </div>


        <div class="row">
            <div class="col-12 p-2 mb-4 " style="background-color: #E7E9EB!important;">
                <p>
                    إلياس آدم زرهوني، إبن الجزائر، أحد الرواد المبتكرين في مجال الأشعة الطبية لأكثر من عقد من الزمن. تحصل على شهادة الدكتوراه في الطب من جامعة الجزائر في عام 1975.
                </p>
                <p>
                    هاجر زرهوني إلى الولايات المتحدة عن عمر يناهز 24 سنة، حيث تحصل على منحة دراسية بجامعة جونز هوبكنز المرموقة، قبل أن يصبح طبيب مقيم رئيسي في عام 1978.
                </p>
                <p>
                    يعتبر الدكتور زرهوني من أبرز مؤسسي التصوير بالرنين المغناطيسي (IRM) في العالم. حيث عمل على تطويره من التشريح الخام إلى التصوير الجزيئي للجسم.
                </p>
            </div>
            <div class="col-12 p-2" style="background-color: #E7E9EB!important;">
                <p>
                    كما كان رائدًا في وضع العلامات المغناطيسية، والتي تعتبر طريقة جديدة لاستخدام التصوير بالرنين المغناطيسي لتتبع حركة القلب ثلاثي الأبعاد وتشخيص سرطان الثدي. كما شارك في بحث حول تقنية التصوير المقطعي المحوسب والتي تميز بين العقيدات الحميدة والخبيثة في الرئتين.
                </p>
                <p>
                    تحصل الدكتور زرهوني على العديد من الجوائز، بما في ذلك الميدالية الذهبية عن بحثه في التصوير المقطعي الطبي المحوسب من طرف جمعية رونتجن راي الأمريكية، وجائزتي بول لوتربور عن أبحاثه في التصوير بالرنين المغناطيسي.
                </p>
                <p>
                    من 2011 إلى 2018، شغل الدكتور زرهوني منصب رئيس البحث والتطوير في Sanofi Monde، وخلال هذه الفترة حصلت هذه الشركة العالمية على الموافقة لـ 13 دواءً جديدًا.
                </p>
                <p>
                    قام الدكتور زرهوني بتأليف أكثر من 200 منشور علمي والحصول على ثماني براءات اختراع كما أسس أو شارك في تأسيس خمس شركات مبتكرة.
                </p>
                <p>
                    تم تعيين الدكتور زرهوني كمدير للمعاهد الوطنية للصحة من قبل الرئيس الأمريكي جورج بوش (2002-2008) وكسفير خاص للعلوم والتكنولوجيا في البلدان الإسلامية من قبل الرئيس باراك أوباما في 2010. كما تم تعيينه عام 2020 كمستشار خاص لدى الوكالة الوطنية للأمن الصحي من طرف رئيس الجمهورية، عبد المجيد تبون.
                </p>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "zerhouni"
    }
</script>

<style scoped>

    .zerhouni h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .centre-title {
        width: 100%;
        max-width: 100%;
        display: flex;
        font-size: var(--xl-font-size);
        font-weight: 700;
        justify-content: center;
        margin-bottom: 20px;
    }
    .centre-title .quote{
        color: var(--green);;
        font-size: var(--m-font-size);
        vertical-align: text-top!important;

    }

</style>