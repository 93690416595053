<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>
    <div class="container" dir="rtl">
        <div class="activities mt-4">
            <h4><span>|</span>تنصيب المجلس العلمي للوكالة الوطنية للأمن الصحي</h4>
            <div class="date">
                <span> 10 ماي 2022</span>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <img src="../../assets/activities/20220510.jpg" class="img-thumbnail" alt="...">
                </div>
                <div class="col-md-6">
                    <img src="../../assets/activities/2022051001.jpg" class="img-thumbnail" alt="...">
                </div>
            </div>
            <div class="description">
                <p class="mb-2">
                    تم تنصيب المجلس العلمي للوكالة الوطنية للأمن الصحي يوم 10  ماي 2021  بحضور كافة أعضائه.
                </p>
                <p class="mb-2">
                    يمثل المجلس العلمي جهاز توجيه استراتيجي، قيادي وعلمي للوكالة. ويبدي رأيه في خصوص كل مسألة تتعلق بمهام الوكالة. ويتشكل المجـــلس العلمي مـــن:
                </p>

                <div class="title">السيد محمد صديق آيت مسعودان، رئيس المجلس العلمي للوكالة </div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي مصطفى باشا
                    </p>
                    <p>
                        الطب، أمراض القلب والإيقاع التداخلي، البحث الطبي في أمراض القلب
                    </p>
                </div>

                <div class="title">السيد كمال صنهاجي، رئيس الوكالة </div>
                <div class="details_act">
                    <p>
                        الوكالة الوطنية للأمن الصحي
                    </p>
                    <p>
                        العلوم الصيدلانية، علوم المناعة الأساسية، علم الأدوية المناعية، علم المناعة في زرع الأعضاء والخلايا الجذعية، القصور المناعي (الأولية والمكتسبة)، العلاج الجيني والعلاج بالخلايا.
                    </p>
                </div>

                <div class="title">السيد نور الدين سماعيل </div>
                <div class="details_act">
                    <p>
                        المعهد الوطني للصحة العمومية
                    </p>
                    <p>
                        الإحصاء الحيوي ومنهجية البحث، وبائيات السرطان. وبائيات الأمراض المعدية؛ وبائيات مرض السكري.
                    </p>
                </div>

                <div class="title">السيد علي لونيسي </div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي تلمسان
                    </p>
                    <p>
                        الطب الداخلي، المؤشرات السريرية الحيوية لمقاومة الأنسولين (متلازمة الأيض) والوقاية من أمراض الأوعية الدموية، الكشف عن داء السكري وعوامل الخطر على الاوعية الدموية الأمراض الجهازية، هشاشة العظام والتخثر.
                    </p>
                </div>

                <div class="title">السيد سمير صالح سفيان </div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي مصطفى باشا
                    </p>
                    <p>
                        أمراض المناعة الذاتية، الوراثة المناعية لأمراض قصور المناعة، المناعة واللقاحات المضادة للعدوى، تقنيات التشخص والبيولوجيا الجزيئية.
                    </p>
                </div>

                <div class="title">السيد كمال جنوحات </div>
                <div class="details_act">
                    <p>
                        المستشفى العام رويبة
                    </p>
                    <p>
                        علم المناعة، البيولوجيا الطبية والأمراض النادرة، بيداغوجية ومنهجية التدريس.
                    </p>
                </div>

                <div class="title">السيد نور الدين رياض طموزة</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي هنري موندور (فرنسا)
                    </p>
                    <p>
                        أمراض الدم، علم المناعة، علم الوراثة وعلم الوراثة المناعية، الزرع، مناعة الطب النفسي.
                    </p>
                </div>

                <div class="title">السيدة دليلة نافعي</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي مصطفى باشا
                    </p>
                    <p>
                        طب العمل، الصحة والأمن في العمل، الوقاية من المخاطر في محيط العمل.
                    </p>
                </div>

                <div class="title">السيدة سهيل طليبة</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي فرانس فانون
                    </p>
                    <p>
                        جراحة الاعصاب، التنظير الداخلي، التشنجات اللاإرادية في التعليم الطبي، تدقيق وتقييم كيانات التدريب الطبي.
                    </p>
                </div>

                <div class="title">السيد حسن محفوف</div>
                <div class="details_act">
                    <p>
                        المستشفى العام رويبة
                    </p>
                    <p>
                        علم الأورام الطبية، خبير في البحث السريري.
                    </p>
                </div>

                <div class="title">السيد هاشمي معوش</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي بادي حسان الحراش
                    </p>
                    <p>
                        أمراض الجهاز التنفسي والربو عند الأطفال.
                    </p>
                </div>

                <div class="title">السيدة نسيمة عاشور</div>
                <div class="details_act">
                    <p>
                        المؤسسة الاستشفائية الهادي فليسي
                    </p>
                    <p>
                        الأمراض المعدية (فيروس نقص المناعة البشري، كوفيد-19، التهاب الكبد، إلخ)، مخاطر الجهاز التنفسي والتطعيمات.
                    </p>
                </div>

                <div class="title">السيدة جميلة حمادي</div>
                <div class="details_act">
                    <p>
                        المعهد الوطني للصحة العمومية
                    </p>
                    <p>
                        الطب الاستوائي والطفيليات.
                    </p>
                </div>

                <div class="title">السيدة شافية طويل بوقفة</div>
                <div class="details_act">
                    <p>
                        جامعة العلوم والتكنولوجيا هواري بومدين
                    </p>
                    <p>
                        علم الأمراض المناعية، التنظيم المناعي، التكنولوجيا الحيوية في الصحة.
                    </p>
                </div>

                <div class="title">السيدة ليندة بن حاجة</div>
                <div class="details_act">
                    <p>
                        المدرسة الوطنية العليا لعلوم البحر والتهيئة الساحلية
                    </p>
                    <p>
                        الهندسة البيئية / معالجة النفايات وإدارتها، السلامة الغذائية،
                        معايير
                         ISO 22000 /ISO 9001
                    </p>
                </div>

                <div class="title">السيدة ختيمة آيت أودية</div>
                <div class="details_act">
                    <p>
                        المديرية العامة للبحث العلمي والتطوير التكنولوجي
                    </p>
                    <p>
                        علم الأوبئة الحيوانية، الأمراض المعدية حيوانية المنشأ، علم الطفيليات وعلم الأمراض الطفيلية، أمراض النواقل.
                    </p>
                </div>

                <div class="title">السيدة حسيبة بنزينة</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي وهران
                    </p>
                    <p>
                        علوم الصحة والبيئة، علم السموم الدوائية، اليقظة السمية، علوم السموم الصناعية والبيئية.
                    </p>
                </div>

                <div class="title">السيدة فازية جنان</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي بني مسوس
                    </p>
                    <p>
                        علم الأحياء الدقيقة الطبية، مقاومة المضادات الحيوية، التشخيص الميكروبيولوجي لعدوى جرثومة المعدة ورصد مقاومته للمضادات الحيوية في أمراض الجهاز الهضمي، النظافة الاستشفائية، علم الأعصاب.
                    </p>
                </div>

                <div class="title">السيدة فاطمة الزهراء غناسي</div>
                <div class="details_act">
                    <p>
                        المركز الاستشفائي الجامعي مصطفى باشا
                    </p>
                    <p>
                        صياغة الأدوية وتصنيعها، صياغة اللقاحات وتصنيعها، البحث العلمي في مجال العلاجات الكيميائية والبيولوجية.
                    </p>
                </div>

                <div class="title">السيد كمال عمورة</div>
                <div class="details_act">
                    <p>
                        مركز مكافحة السرطان عنابة
                    </p>
                    <p>
                        النظافة الاستشفائية، مقاومة المضادات الحيوية، علم الأحياء الجزيئي (البيولوجيا الجزيئية).
                    </p>
                </div>

                <div class="title">السيد عبد السلام آعراب</div>
                <div class="details_act">
                    <p>
                        جامعة العلوم والتكنولوجيا هواري بومدين
                    </p>
                    <p>
                        علم البيئة الحيوانية، علم الأحياء المائية، تربية الأحياء المائية.
                    </p>
                </div>

                <div class="title">السيدة ليلى عشوبي</div>
                <div class="details_act">
                    <p>
                        جامعة العلوم والتكنولوجيا هواري بومدين
                    </p>
                    <p>
                        النظم البيئية الجزائرية (الغابات والسهوب والصحراء)، الأنواع النباتية الجزائرية، تحديد الأخطار التي تهدد النظم البيئية (التلوث، مبيدات الآفات).
                    </p>
                </div>

                <div class="title">السيد عادل بلوشراني</div>
                <div class="details_act">
                    <p>
                        المدرسة الوطنية للفنون التطبيقية
                    </p>
                    <p>
                        معالجة الإشارات الإحصائية، معالجة المصفوفة، رياضيات تطبيقية.
                    </p>
                </div>

                <div class="title">السيد فيصل حمدي</div>
                <div class="details_act">
                    <p>
                        جامعة العلوم والتكنولوجيا هواري بومدين
                    </p>
                    <p>
                        النمذجة الإحصائية، الاقتصاد القياسي، السلاسل الزمنية ونماذج التنبؤ.
                    </p>
                </div>

                <div class="title">السيد عبد الرحمان يوسفات</div>
                <div class="details_act">
                    <p>
                        جامعة سيدي بلعباس
                    </p>
                    <p>
                        الإحصاء الوظيفي والعملي، الإحصاء الوصفي الاستنتاجي على نموذج ماركوف، الهندسة التفاضلية والإحصائية، تحليل البيانات.
                    </p>
                </div>

                <div class="title">السيد ياسين شعلال</div>
                <div class="details_act">
                    <p>
                        المدرسة العليا للإعلام الآلي
                    </p>
                    <p>
                        أمن البيانات والاتصالات، معالجة البيانات مع الحفاظ على الخصوصية.
                    </p>
                </div>
                <div class="title">السيد جمال الدين اكرتش</div>
                <div class="details_act">
                    <p>
                        جامعة العلوم والتكنولوجيا هواري بومدين
                    </p>
                    <p>
                        كيمياء المحاليل والكيمياء التحليلية، الكيمياء الكهربائية التطبيقية، معالجة المخلفات الصلبة أو السائلة بطرق مختلفة.
                    </p>
                </div>

                <div class="title">السيد حمماش خدوسي</div>
                <div class="details_act">
                    <p>
                        جامعة ليون 1 (فرنسا)
                    </p>
                    <p>
                        الخوارزميات والرسوم البيانية، الذكاء الاصطناعي، البيانات الضخمة، شبكات التواصل الاجتماعي، الأمن الرقمي.
                    </p>
                </div>

                <div class="title">السيد عبد السلام صغور</div>
                <div class="details_act">
                    <p>
                        مركز البحوث البيئية
                    </p>
                    <p>
                        الأمن النووي والأمن الإشعاعي، الطيف النووي، الحماية من الإشعاع وقياس الجرعات الإشعاعية.
                    </p>
                </div>

                <div class="title">السيد جواد براهم بوركايب</div>
                <div class="details_act">
                    <p>
                        وزارة الصناعة الصيدلانية
                    </p>
                    <p>
                        الإدارة الإستراتيجية لمؤسسات الضمان الاجتماعي، علاقات الصحة بالضمان الاجتماعي، السياسة الدوائية، التواصل الاستراتيجي.
                    </p>
                </div>

                <div class="title">السيدة زهاد معامشة</div>
                <div class="details_act">
                    <p>
                        مركز البحث في البيئة
                    </p>
                    <p>
                        التنوع البيولوجي للتراث (تثمين وحماية وإدارة)، الصحة والبيئة الصحية، المناخ، الطاقات المتجددة، علم البيئة البشرية، العمران والتخطيط الحضري، الابتكار والتنمية المستدامة، الاقتصاد الأخضر والأزرق، الاقتصاد الدائري.
                    </p>
                </div>

                <p class="mt-3 mb-2">
                    ولقد تم انتخاب البروفيسور محمــد صديق آيت مسعودان رئيسا للمجلس.
                </p>
                <p class="mb-2">
                    للتذكير، يتعين أعضـــاء المجـــلس العلمي بمــوجـــب قرار من مديـر ديوان رئاسة الجمهورية، بـناء على اقتـراح من رئيس الوكالة، لمدة أربع (04) سنوات قابلة للتجديد. وتنهى مهامهم حسب الأشكال نفسها.
                </p>
                <p class="mb-5">
                    يجتمع المجلس العلمي مرة واحدة، على الأقل، كل ستة (06) أشهر، بناء على استدعاء من رئيسه. ويمكنه، زيادة على ذلك، الاجتماع في دورة غير عادية، بناء على استدعاء من رئيسه، باقتراح من ثلثي (2/3) أعضائه أو بطلب من رئيس الوكالة. تضمن مصالح الوكالة أمانة المجلس العلمي.
                </p>

            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "Act-01"
    }
</script>

<style scoped>
    .activities{
        font-family: DroidArabic!important;

    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 40px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }
    .title {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: var(--l-font-size);
        font-weight: 700;
        padding-top: calc(35/22 * var(--default-size-unit))!important;
        padding-bottom: calc(35/22 * var(--default-size-unit))!important;
    }
    .title::before {
        content: ' ';
        display: block;
        -webkit-margin-end: var(--default-size-unit);
        margin-inline-end: var(--default-size-unit);
        width: calc(35/16 * var(--default-size-unit));
        height: calc(8/16 * var(--default-size-unit));
        background: var(--primary-text-color);
        min-width: calc(35/16 * var(--default-size-unit));
    }
    .details_act p {
        padding: 10px;
        margin-bottom: 10px!important;
    }
</style>