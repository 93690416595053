<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{  $t('activities.title') }}</div>
        </div>
    </div>
    <div class="container mb-5" dir="rtl">
        <div class="activities mt-4">
            <h4><span>|</span>{{ article.title }}</h4>
            <div class="date">
                <span>{{ date_article(article.date_article) }}</span>
            </div>
            <!--<div>-->
                <!--<meta-info-->
                <!--:title="pageTitle"-->
                <!--:description="pageDescription"-->
                <!--:image="PageImage"-->
                <!--/>-->
            <!--</div>-->

            <!--One-->
            <div v-if="photos_rows === 1" class="row justify-content-center">
                <div v-for="photo in photos" class="col-md-6">
                    <a target="_blank" :href="url_back +'/images/activities/' + photo.url_big">
                        <img :src="url_back +'/images/activities/' + photo.url_big" width="500" class="img-thumbnail float-end float-md-start" alt="...">
                    </a>
                </div>
            </div>
            <!--Two-->
            <div v-if="photos_rows === 2" class="row mt-4">
                <div v-for="photo in photos" class="col-md-6">
                    <a target="_blank" :href="url_back +'/images/activities/' + photo.url_big">
                        <img :src="url_back +'/images/activities/' + photo.url_big" width="500" class="img-thumbnail float-end float-md-start justify-content-center" alt="...">
                    </a>
                </div>
            </div>
            <!--Three-->
            <div v-if="photos_rows === 3" class="row mt-4">
                <div v-for="photo in photos" class="col-md-4">
                    <a target="_blank" :href="url_back +'/images/activities/' + photo.url_big">
                        <img :src="url_back +'/images/activities/' + photo.url_big" width="400" class="img-thumbnail" alt="...">
                    </a>
                </div>
            </div>
            <!--Four-->
            <div v-if="photos_rows >= 4" class="row mt-4">
                <Carousel :autoplay="5000" :itemsToShow="2.5" :wrapAround="true">
                    <Slide v-for="photo in photos" :key="photo.id">
                        <div class="carousel__item">
                            <a target="_blank" :href="url_back +'/images/activities/' + photo.url_big">
                                <img :src="url_back +'/images/activities/' + photo.url_big" class="img-thumbnail" alt="...">
                            </a>
                        </div>
                    </Slide>
                </Carousel>
            </div>

            <!--Description of article-->
            <div class="description">
                <div class="text-block" v-html="article.description"></div>
            </div>

            <!--Share on social media-->
            <div class="social_media text-start">
                <h6>مشاركة المقال</h6>
                <div class="social_icons">
                    <a target="_blank" class="share-ico share-fb" :href="'https://www.facebook.com/sharer/sharer.php?u='+'https://nssa.el-mouradia.dz/detail_article/'+article.id ">
                        <font-awesome-icon icon="fa-brands fa-facebook-f" />
                    </a>
                    <a target="_blank" class="share-ico share-tw" :href="'https://twitter.com/intent/tweet?text='+'https://nssa.el-mouradia.dz/detail_article/'+article.id">
                        <font-awesome-icon icon="fa-brands fa-twitter" />
                    </a>
                    <a target="_blank" class="share-ico share-link" :href="'https://www.linkedin.com/shareArticle?url='+'https://nssa.el-mouradia.dz/detail_article/'+article.id">
                        <font-awesome-icon icon="fa-brands fa-linkedin-in" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';
    // import vueMeta from 'vue-meta';

    import 'vue3-carousel/dist/carousel.css';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

    export default {
        name: "Detail_article",
        components: {
            Carousel,
            Slide,
            Pagination
        },
        props:['title','description','image'],
        // metaInfo(){
        //     return {
        //         title: 'الوكالة الوطنية للأمن الصحي',
        //         meta: [
        //             {
        //                 property: 'og:title',
        //                 content: 'title NSSA'
        //             },
        //             {
        //                 property: 'og:description',
        //                 content: 'description NSSA'
        //             },
        //             {
        //                 property: 'og:image',
        //                 content: 'https://nssa.el-mouradia.dz/images/WordPresident.jpg'
        //             }
        //         ]
        //     };
        // },
        data(){
            return {
                article:{},
                photos:this.photos,
                photos_rows: this.photos_rows,
                url_back: 'https://backend-nssa.el-mouradia.dz/uploads/'
            }
        },
        created(){
            this.getArticleById();
        },
        methods:{
            async getArticleById(){
                let article_id = this.$route.params.id;
                if(!article_id){
                    this.$router.replace({ path: '/PageNotFound' });
                    document.title = "الوكالة الوطنية للأمن الصحي";
                }
                if(!Number.isNaN(Number(article_id)) && article_id > 0){
                    let url = `https://backend-nssa.el-mouradia.dz/api/detail_article/${this.$route.params.id}`;
                    await axios.get(url).then(response => {
                        // console.log(response);
                        if(Object.keys(response.data).length){
                            this.article = response.data;
                            let url_page = `https://nssa.el-mouradia.dz/detail_article/${this.$route.params.id}`;
                            document.title = response.data.title;
                            document.querySelector('meta[property="og:url"]').setAttribute("content", url_page);
                            document.querySelector('meta[property="og:type"]').setAttribute("content", "Website");
                            document.querySelector('meta[property="og:title"]').setAttribute("content", response.data.title);
                            document.querySelector('meta[property="og:description"]').setAttribute("content", response.data.description);
                            document.querySelector('meta[property="og:image"]').setAttribute("content", "https://nssa.el-mouradia.dz/images/WordPresident.jpg");
                        }else {
                            this.$router.replace({ path: '/PageNotFound' });
                            document.title = "الوكالة الوطنية للأمن الصحي";
                        }
                    });

                    let url_photos = `https://backend-nssa.el-mouradia.dz/api/photos/${this.$route.params.id}`;
                    await axios.get(url_photos).then(response => {
                        let nb_photos = Object.keys(response.data).length;
                        this.photos_rows = nb_photos;
                        this.photos = response.data;
                        // console.log(nb_photos);
                        // console.log(response.data);
                    });
                }
                else {
                    this.$router.replace({ path: '/PageNotFound' });
                }
            },
            date_article(value){
                let day = moment(value).format('DD');
                let month = moment(value).format('MM');
                let year = moment(value).format('YYYY');

                if(month === "01"){
                    month = "جانفي";
                }else if(month === "02"){
                    month = "فيفري";
                }else if(month === "03"){
                    month = "مارس";
                }else if(month === "04"){
                    month = "أفريل";
                }else if(month === "05"){
                    month = "ماي";
                }else if(month === "06"){
                    month = "جوان";
                }else if(month === "07"){
                    month = "جويلية";
                }else if(month === "08"){
                    month = "أوت";
                }else if(month === "09"){
                    month = "سبتمبر";
                }else if(month === "10"){
                    month = "أكتوبر";
                }else if(month === "11"){
                    month = "نوفمبر";
                }else if(month === "12"){
                    month = "ديسمبر";
                }
                return day +' '+  month +' '+ year;
            },
        },
        mounted: function () {
            console.log('Fetch details data of article ...')
        }
    }
</script>

<style scoped>
    .text-block {
        white-space: pre-line;
        line-height: 30px;
        text-align: justify;
    }

    .activities{
        font-family: DroidArabic!important;
    }
    .activities h4{
        margin-bottom: 25px;
    }
    .activities h4 span{
        color: red;
        font-size: 35px;
        font-weight: bold;
        margin-left: 10px
    }
    .date span{
        font-size: 16px!important;
        margin-right: 20px;
    }
    .date{
        margin-bottom: 20px!important;
    }
    .description{
        margin-top: 30px!important;
    }
    .section {
        display: flex;
        align-items: center;
        font-size: var(--xs-font-size);
        font-weight: bold;
        background: var(--secondary-background-color);
        padding: calc(7/16 * var(--default-size-unit)) calc((100vw - 1920px)/2 +  var(--body-padding));
        border-top: var(--input-border);
        border-bottom: var(--input-border);
        font-family: DroidArabic!important;
    }
    .breadcrumb{
        text-decoration: none!important;
        margin: 0;
        color: #37414D;
    }

    /* Start carousel*/

    .carousel__item {
        min-height: 200px;
        width: 100%;
        /*background-color: var(--vc-clr-primary);*/
        /*color:  var(--vc-clr-white);*/
        font-size: 20px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousel__slide {
        padding: 10px;
    }
    .carousel__prev,
    .carousel__next {
        box-sizing: content-box;
        border: 5px solid red;
    }

    .carousel__slide > .carousel__item {
        transform: scale(1);
        opacity: 0.5;
        transition: 0.5s;
    }
    .carousel__slide--visible > .carousel__item {
        opacity: 1;
        transform: rotateY(0);
    }
    .carousel__slide--next > .carousel__item {
        transform: scale(0.9) translate(-10px);
    }
    .carousel__slide--prev > .carousel__item {
        transform: scale(0.9) translate(10px);
    }
    .carousel__slide--active > .carousel__item {
        transform: scale(1.1);
    }

    /*End Carousel*/

    /*start social media*/
    .sm-share h6{
        font-family: Droid!important;
        font-size: 15px;
        color: #17a2b8;
    }
    .social_media h6{
        color: #17a2b8;
    }
    .share-ico{
        color: #999999;
        font-size: 1.5em;
        margin-left: 8px;
    }
    .share-fb{
        color: #3b5999;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }
    .share-tw{
        color: #55acee;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }
    .share-link{
        color: #0077B5;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
    }

    /*End social media*/

</style>