<template>
    <div class="section" dir="rtl">
        <div class="container">
            <div class="breadcrumb mb-10 text-bold" id="biography" > {{  $t('agency.title') }} > {{ $t('agency.organizationalStructure.title') }}</div>
        </div>
    </div>

    <div class="container " style="font-family: Cairo,sans-serif!important;">
        <div dir="rtl">
            <div class="title" >الهيكل التنظيمي</div>
        </div>
        <div class="row">
            <div class="col-12 mt-4">
                <div class="">
                    <a target="_blank" href="/images/organigramme.png">
                        <img width="100%" src="/images/organigramme.png"  class="" />
                    </a>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrganizationalStructure"
    }
</script>

<style scoped>


</style>